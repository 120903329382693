import { Injectable } from '@angular/core';
import { CanLoad, Route } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';

@Injectable()
export class AuthGuard implements CanLoad {

	constructor(private authService: AuthenticationService) {
	}

	canLoad(route: Route): boolean {
		const isAccessTokenExpired = this.authService.isAccessTokenExpired();
		const isRefreshTokenExpired = this.authService.isRefreshTokenExpired();

		if (isAccessTokenExpired && isRefreshTokenExpired) {
			const redirectUrl = window.location.pathname + window.location.search;
			localStorage.setItem('redirectUrl', redirectUrl);

			this.authService.logout();

			return undefined;
		}

		return true;
	}
}
