
import { ReportMetrics } from '../../main/reports/models/report-metrics.model';
import { Report } from '../../main/reports/models/report.model';
import { TenureReport } from '../../main/reports/tenure-reports/models/tenure-report.model';
import { TurnoverReport } from '../../main/reports/turnover-reports/models/turnover-report.model';

export class ChartsDataUtil {

	/**
	 * Map report array into ChartData
	 * @param data - Contains names of category with their data by periods
	 * @returns {ChartData} Object containing categories and series
	 */
	public mapResponseDetails<T extends Report<ReportMetrics>>(data: T[]): ChartData {
		let result: ChartData = {
			categories: [],
			series: {}
		};

		data.forEach(d => {
			result.categories.push(d.name);

			d.values.forEach(r => {
				if (!(r.name in result.series)) {
					result.series[r.name] = [];
				}

				if (r.value > 0) {
					r.value = Math.round(r.value);
				}

				result.series[r.name].push(r.value);
			});
		});

		Object.getOwnPropertyNames(result.series).forEach(key => {
			let arr: any[] = result.series[key];
			let length = arr.filter(x => x !== -1).length;

			// -2 is the value returned from the API if there is no data at the beginning and end of period for turnovers
			for (let i = 0, j = arr.length; i < j; i++) {
				if (arr[i] === -2) {
					arr[i] = '-';
				}
			}

			if (length === 0) {
				delete result.series[key];
			} else if (length !== arr.length) {
				for (let i = 0, j = arr.length; i < j; i++) {
					if (arr[i] === -1) {
						arr[i] = '-';
					}
				}
			}
		});

		return result;
	}

	public mapTenureResponseDetails(data: TenureReport[]): ChartData {
		let result: ChartData = {
			categories: [],
			series: {}
		};

		if (data.length === 1 && data[0].name === '$$EMPTY_REPORT$$') {
			data[0].values.forEach(r => {
				result.series[r.name] = [];
			});

			return result;
		}

		data.forEach(d => {
			result.categories.push(d.name);

			d.values.forEach(r => {
				if (!(r.name in result.series)) {
					result.series[r.name] = [];
				}

				result.series[r.name].push(r.average);
			});
		});

		return result;
	}

	public mapDepartedResponseDetails(data: TurnoverReport[]): ChartData {
		let result: ChartData = {
			categories: [],
			series: {}
		};

		data.forEach(d => {
			result.categories.push(d.name);

			d.values.forEach(r => {
				if (!(r.name in result.series)) {
					result.series[r.name] = [];
				}

				if (!r.departed) {
					r.departed = [];
				}

				result.series[r.name].push(r.departed.length);
			});
		});

		return result;
	}
}

export interface ChartDataDict {
	name: string;
	data: ChartData;
}

export interface ChartData {
	categories: string[];
	series: Data;
}

export interface Data {
	[name: string]: any[];
}
