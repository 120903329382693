import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpInterceptor } from '../../../core/http/http.interceptor';
import { RequestOptions } from '../../../core/interfaces/request-options.interface';
import { CrudTempService } from '../../../core/services/crud-temp.service';
import { Location } from '../../organization/locations/models/location.model';

@Injectable()
export class TempLocationsService extends CrudTempService<Location, RequestOptions<Location>> {

	constructor(http: HttpInterceptor) {
		super(http, '/import/locations');
	}

	public getAll(options: RequestOptions<Location>, suffix?: string): Observable<HttpResponse<any>> {
		this.addExpands(options);
		return this.http.get(this.getConsolidationUri(), ['auth']);
	}
}
