import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthInterceptor } from '@app/core/interceptors/auth.interceptor';
import { SocketIoService } from '@app/core/services/socket-io.service';
import { CookieService } from 'ngx-cookie';
import { AdpAuthService } from '../auth/services/adp-auth.service';
import { AdpImportService } from '../main/import/import-options/adp-import/services/adp-import.service';
import { BlobErrorHttpInterceptor } from './http/blob-error-http.interceptor';
import { ACLGuard } from './http/guards/acl.guard';
import { AuthGuard } from './http/guards/auth.guard';
import { DashboardGuard } from './http/guards/dashboard.guard';
import { GoalsRedirectGuard } from './http/guards/goals-redirect.guard';
import { HasPendingChangesGuard } from './http/guards/has-pending-changes.guard';
import { ImportStepGuard } from './http/guards/import-step.guard';
import { ManagerGuard } from './http/guards/manager.guard';
import { MyTeamRedirectGuard } from './http/guards/my-team-redirect.guard';
import { NonAuthenticatedGuard } from './http/guards/non-authenticated.guard';
import { OrganizationRecognitionsRedirectGuard } from './http/guards/organization-recognitions-redirect.guard';
import { OrganizationSettingsRedirectGuard } from './http/guards/organization-settings-redirect.guard';
import { ProfileACLGuard } from './http/guards/profile-acl.guard';
import { TempOrganizationExistsGuard } from './http/guards/temp-organization-exists.guard';
import { AccountService } from './services/account.service';
import { FileService } from './services/file.service';
import { FormValidationService } from './services/form-validation.service';
import { GatewayService } from './services/gateway.service';
import { LocaleService } from './services/locale.service';
import { MessagesService } from './services/messages.service';
import { NotificationMessagesService } from './services/notification-messages.service';
import { OrganizationService } from './services/organization.service';
import { OrganizationsService } from './services/organizations.service';
import { ValidationService } from './services/validation.service';
import { ChartsDataUtil } from './utils/charts-data.util';
import { ExportUtil } from './utils/export.util';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [],
	entryComponents: [],
	exports: [],
	providers: [
		CookieService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: BlobErrorHttpInterceptor,
			multi: true
		},
		SocketIoService,
		AdpAuthService,
		AdpImportService,
		AuthGuard,
		ACLGuard,
		ProfileACLGuard,
		HasPendingChangesGuard,
		NonAuthenticatedGuard,
		DashboardGuard,
		GoalsRedirectGuard,
		ImportStepGuard,
		TempOrganizationExistsGuard,
		ManagerGuard,
		GatewayService,
		OrganizationService,
		OrganizationSettingsRedirectGuard,
		OrganizationRecognitionsRedirectGuard,
		MyTeamRedirectGuard,
		OrganizationsService,
		AccountService,
		LocaleService,
		ValidationService,
		FormValidationService,
		FileService,
		NotificationMessagesService,
		ExportUtil,
		ChartsDataUtil
	]
})
export class CoreModule {
}
