export class Tenure {
	years: number;
	months: number;
	days: number;
	hash: number;

	tenureDisplay: string;

	constructor() {
		this.tenureDisplay = '-';
	}

	static reformatTenure(tenure: Tenure): string {
		let tenureDisplay = '';

		if (!tenure || tenure.hash < 0) {
			return tenureDisplay;
		}

		let yearsStr = 'years';
		let monthsStr = 'months';
		let daysStr = 'days';

		if (tenure.years === 1) {
			yearsStr = 'year';
		}
		if (tenure.months === 1) {
			monthsStr = 'month';
		}
		if (tenure.days === 1) {
			daysStr = 'day';
		}

		if (tenure.years > 0) {
			if (tenure.months === 0) {
				tenureDisplay = tenure.years + ' ' + yearsStr;
			} else {
				tenureDisplay = tenure.years + ' ' + yearsStr + ' ' + tenure.months + ' ' + monthsStr;
			}
		} else if (tenure.months > 0) {
			if (tenure.days === 0) {
				tenureDisplay = tenure.months + ' ' + monthsStr;
			} else {
				tenureDisplay = tenure.months + ' ' + monthsStr + ' ' + tenure.days + ' ' + daysStr;
			}
		} else {
			tenureDisplay = tenure.days + ' ' + daysStr;
		}

		return tenureDisplay;
	}

	static getTenureDisplay(tenure: Tenure): string {
		if (!tenure) {
			tenure.tenureDisplay = '-';
			return tenure.tenureDisplay;
		}

		if (tenure.years > 0) {
			if (tenure.months > 0) {
				tenure.tenureDisplay = `${tenure.years}y, ${tenure.months}m`;
			} else {
				tenure.tenureDisplay = `${tenure.years}y, 0m, ${tenure.days}d`;
			}
		} else if (tenure.months > 0) {
			tenure.tenureDisplay = `${tenure.months}m, ${tenure.days}d`;
		} else {
			tenure.tenureDisplay = `${tenure.days}d`;
		}

		return tenure.tenureDisplay;
	}
}
