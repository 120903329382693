import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';
import { AppGlobals } from '../../common/app.globals';

@Injectable()
export class ImportStepGuard implements CanActivate {
	constructor(private cookieService: CookieService,
				private router: Router) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		if (this.router.url.startsWith('/import/')) {
			return true;
		}

		const step = this.cookieService.get('import-progress');
		if (step === '1-2' && AppGlobals.hasComponent('ORG_ADP_INTEGRATION')) {
			this.router.navigate(['import/import-data/adp']);
		} else if (step === '2') {
			this.router.navigate(['import/employees']);
		} else if (step === '3') {
			this.router.navigate(['import/org-chart']);
		} else if (step === '4') {
			this.router.navigate(['import/finish']);
		}

		return true;
	}
}
