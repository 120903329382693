export class ErrorResponse<E extends Errors> {
	status: ResponseStatus;
	code: number;
	errors?: E;

	static getFieldError<E extends Errors>(response: ErrorResponse<E>, field: string): Error {
		if (!response.errors) {
			return null;
		}

		let errors = response.errors[field];
		if (!errors || !errors.length) {
			return null;
		}

		return errors[0];
	}

	static getFirstError<E extends Errors>(response: ErrorResponse<E>): Error {
		if (!response.errors) {
			return null;
		}

		for (let key in response.errors) {
			if (response.errors.hasOwnProperty(key)) {
				return response.errors[key][0];
			}
		}
	}
}

export enum ResponseStatus {
	NOT_FOUND,
	BAD_REQUEST,
	CONFLICT,
	UNAUTHORIZED,
	INTERNAL_SERVER_ERROR
}

export class Errors {
	[field: string]: Error[]
}

export class Error {
	code: string;
	message: string;
	arguments: any[];
}
