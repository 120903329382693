import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppGlobals } from '../../common/app.globals';

@Injectable()
export class ACLGuard implements CanActivate, CanLoad {

	constructor(private router: Router) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		const check = route.data['aclCheck'];
		const hasAccess = this.hasAccess(route.data['acl'], check);

		if (!hasAccess) {
			this.router.navigate(['/']);
		}

		return hasAccess;
	}


	canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
		const check = route.data['aclCheck'];
		const hasAccess = this.hasAccess(route.data['acl'], check);

		if (!hasAccess) {
			this.router.navigate(['/dashboard']);
		}

		return hasAccess;
	}

	private hasAccess(components: string[], check: string): boolean {
		const userComponents = AppGlobals.getLoggedUser().components;

		if (components.length === 0) {
			return userComponents.indexOf(components[0]) > -1;
		}

		const matchedComponents = [];

		for (let i = 0, j = userComponents.length; i < j; i++) {
			for (let k = 0, m = components.length; k < m; k++) {
				if (userComponents[i] === components[k]) {
					if (check !== 'and') { // if user has any of the permissions
						return true;
					} else { // if user has all permissions
						matchedComponents.push(components[k]);
					}
				}
			}
		}

		return matchedComponents.length === components.length;
	}
}
