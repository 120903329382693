import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpInterceptor } from '../../../core/http/http.interceptor';
import { RequestOptions } from '../../../core/interfaces/request-options.interface';
import { CrudTempService } from '../../../core/services/crud-temp.service';
import { TerminationReason } from '../../organization/termination-reasons/models/termination-reason.model';

@Injectable()
export class TempTerminationReasonsService extends CrudTempService<TerminationReason, RequestOptions<TerminationReason>> {

	constructor(http: HttpInterceptor) {
		super(http, '/import/termination-reasons');
	}

	public getAll(options: RequestOptions<TerminationReason>, suffix?: string): Observable<HttpResponse<any>> {
		this.addExpands(options);
		return this.http.get(this.getConsolidationUri(), ['auth']);
	}
}
