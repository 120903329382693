import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Timezone } from '../../main/account/models/timezone.model';
import { delayedRetry } from '../operators/delayed-retry';
import { CacheService } from '../services/cache.service';
import { LocaleService } from '../services/locale.service';

@Injectable({
	providedIn: 'root'
})
export class TimezonesResolver implements Resolve<Timezone[]> {

	constructor(
		private dataService: LocaleService,
		private cacheService: CacheService) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Timezone[]> {
		return this.dataService.getAvailableTimezones({})
			.pipe(
				delayedRetry(3000),
				map((response) => response.body),
				catchError(() => of([])),
				tap(timezones => this.cacheService.setTimezones(timezones))
			);
	}
}
