<ng-container *ngIf="!(maintenance$ | async)">
	<router-outlet></router-outlet>
</ng-container>
<ng-container *ngIf="maintenance$ | async">
	<!-- Page container -->
	<div class="page-container">
		<!-- Page content -->
		<div class="page-content">
			<!-- Main content -->
			<div class="content-wrapper">
				<!-- Content area -->
				<div class="content">
					<div class="panel panel-body">
						<div class="text-center">
							<div>
								<a href="http://www.helloteam.com">
									<div class="logo">
										<img [src]="brandLogo" alt="" />
									</div>
								</a>
							</div>
						</div>

						<div class="separator"></div>

						<div class="text-center">
							<div class="title">
								{{ brandName }} is currently down for
								maintenance.
							</div>
							<div class="description">
								We expect to be back very soon. Thank you for
								your patience!
							</div>
							<div class="image">
								<img
									src="/assets/images/maintenance.png"
									alt="Maintenance"
								/>
							</div>
							<div class="description">
								New features are on the way!
							</div>
						</div>
					</div>

					<div class="text-center outer-text">
						If you need assistance, please email
						<a href="mailto:support@helloteam.com"
							>support@helloteam.com</a
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>
