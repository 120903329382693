import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment } from '@angular/router';
import { AuthenticationService } from '@app/core/services/authentication.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
/**
 * Resolve user profile in a guard because we need it before the MainModule stars loading
 * Resolver doesn't work in this case because of the `canActivate` and `canLoad` guards inside of the MainModule
 */
export class MeGuard implements CanLoad {

	constructor(private authService: AuthenticationService) {
	}

	canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
		return this.authService.requestUserProfile().pipe(
			map(response => !!response)
		);
	}
}
