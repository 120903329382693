import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpInterceptor } from '../http/http.interceptor';
import { BaseModel } from '../interfaces/base.model';
import { PagedResourceInterface } from '../interfaces/paged-resource.interface';
import { RequestOptions } from '../interfaces/request-options.interface';
import { BaseService } from './base.service';

export abstract class CrudService<T extends BaseModel, O extends RequestOptions<T>> extends BaseService<T, O> {

	constructor(http: HttpInterceptor, collection: string) {
		super(http, collection);
	}

	public getAll(options: O, suffix?: string): Observable<HttpResponse<PagedResourceInterface<T>>> {
		if (!options.size) {
			options.size = 99999;
		}

		return this.http.get(this.buildUrl(options, suffix), ['auth']);
	}

	public getById(options: O): Observable<HttpResponse<T>> {
		if (!options.id) {
			this.handleError('BaseService:getById - No id');
		}

		return this.http.get(this.buildUrl(options), ['auth']);
	}

	public post(options: O): Observable<HttpResponse<T>> {
		return this.http.post(this.buildUrl(options), options.data, ['auth']);
	}

	public patch(options: O): Observable<HttpResponse<T>> {
		return this.http.patch(this.buildUrl(options), options.data, ['auth']);
	}

	public put(options: O): Observable<HttpResponse<T>> {
		return this.http.put(this.buildUrl(options), options.data, ['auth']);
	}

	public save(options: O, usePatch?: boolean): Observable<HttpResponse<T>> {
		if (!options.data) {
			this.handleError('BaseService:save - No data');
		}

		if (!options.data.id) {
			return this.post(options);
		} else {
			if (!usePatch) {
				return this.put(options);
			}

			return this.patch(options);
		}
	}

	public delete(options: O): Observable<HttpResponse<undefined>> {
		return this.http.delete(this.buildUrl(options), ['auth']);
	}

	public count(options: O): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options, '/count'), ['auth']);
	}
}
