import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppGlobals } from '../../common/app.globals';

@Injectable()
export class MyTeamRedirectGuard implements CanActivate {
	privileges: {
		viewGoals?: boolean;
		viewBadges?: boolean;
		assignBadges?: boolean;
		viewReviews?: boolean;
		viewOneOnOnes?: boolean;
		viewFeedback?: boolean;
	};

	constructor(private router: Router) {
	}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

		this.privileges = {
			viewGoals: AppGlobals.hasComponent('GOALS'),
			viewReviews: AppGlobals.hasAnyComponent(['PERFORMANCE_REVIEWS', 'PERFORMANCE_REVIEWS_V2']),
			viewOneOnOnes: AppGlobals.hasComponent('ONE_ON_ONES'),
			viewFeedback: AppGlobals.hasAnyComponent(['FEEDBACKS:VIEW_SUBORDINATES', 'FEEDBACKS:VIEW_MANAGE_ANYONE'])
		};

		const prefix = '/my-team';

		const summary = '/my-team-summary';
		const reviews = '/my-team-reviews';
		const goals = '/my-team-goals';
		const managerComment = '/my-team-manager-comment';
		const oneOnOnes = '/my-team-one-on-ones';
		const feedback = '/my-team-feedback';

		if (state.url === prefix + reviews && !this.privileges.viewReviews) {
			this.router.navigate([prefix + summary]);
			return false;
		} else if (state.url === prefix + goals && !this.privileges.viewGoals) {
			this.router.navigate([prefix + summary]);
			return false;
		} else if (state.url === prefix + managerComment && !this.privileges.viewGoals) {
			this.router.navigate([prefix + summary]);
			return false;
		} else if (state.url === prefix + oneOnOnes && !this.privileges.viewOneOnOnes) {
			this.router.navigate([prefix + summary]);
			return false;
		} else if (state.url === prefix + feedback && !this.privileges.viewFeedback) {
			this.router.navigate([prefix + summary]);
			return false;
		}
		return true;
	}

}
