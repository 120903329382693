import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';

@Injectable()
export class NonAuthenticatedGuard implements CanLoad {

	constructor(private authService: AuthenticationService,
				private router: Router) {
	}

	canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
		const isAccessTokenExpired = this.authService.isAccessTokenExpired();
		const isRefreshTokenExpired = this.authService.isRefreshTokenExpired();

		if (!isAccessTokenExpired || !isRefreshTokenExpired) {
			this.router.navigate(['/']);
			return undefined;
		}

		return true;
	}
}
