import { HttpResponse } from '@angular/common/http';
import { PagedResourceInterface } from '@app/core/interfaces/paged-resource.interface';
import { User } from '@app/main/users/models/user.model';
import { Observable } from 'rxjs';
import { HttpInterceptor } from '../http/http.interceptor';
import { BaseModel } from '../interfaces/base.model';
import { RequestOptions } from '../interfaces/request-options.interface';
import { CrudService } from './crud.service';

export abstract class CrudUsersContainingService<T extends BaseModel, O extends RequestOptions<T>> extends CrudService<T, O> {

	constructor(http: HttpInterceptor, collection: string) {
		super(http, collection);
	}

	public getUsers(options: O): Observable<HttpResponse<PagedResourceInterface<User>>> {
		return this.http.get(this.buildUrl(options, '/users'), ['auth']);
	}
}
