import { Injectable } from '@angular/core';
import { AppGlobals } from '../../common/app.globals';
import { BaseGuard } from './base.guard';

@Injectable()
export class HasPendingChangesGuard extends BaseGuard {

	constructor() {
		super(() => AppGlobals.hasPendingChangesSubject);
	}

	// TODO Move in BaseGuard
	canDeactivate(): boolean {
		let value = this.provider();
		let toReturn;

		value.subscribe(val => {
			toReturn = val.hasPendingChanges;
			return !toReturn;
		});

		return !toReturn;
	}

	protected handle(val: any): boolean {
		return !val;
	}
}
