import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { UserGroup } from '../../main/organization/user-groups/models/user-group.model';
import { UserGroupsService } from '../../main/organization/user-groups/services/user-groups.service';
import { delayedRetry } from '../operators/delayed-retry';
import { CacheService } from '../services/cache.service';

@Injectable({
	providedIn: 'root'
})
export class TeamsResolver implements Resolve<UserGroup[]> {

	constructor(private userGroupsService: UserGroupsService,
				private cacheService: CacheService) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UserGroup[]> {
		return this.userGroupsService.getAll({
			sort: {
				field: 'name'
			}
		}).pipe(
			delayedRetry(3000),
			map((response) => response.body.content),
			catchError(() => of([])),
			tap(teams => this.cacheService.setTeams(teams))
		);
	}
}
