import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpInterceptor } from '../../../../../core/http/http.interceptor';
import { RequestOptions } from '../../../../../core/interfaces/request-options.interface';
import { BaseService } from '../../../../../core/services/base.service';
import { AdpImportStatus } from '../../../models/adp-import-status.model';

@Injectable()
export class AdpImportService extends BaseService<any, RequestOptions<any>> {

	constructor(http: HttpInterceptor) {
		super(http, '/import/adp');
	}

	getLocation(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options), ['auth']);
	}

	importData(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options), options.data, ['auth']);
	}

	getStatus(options: RequestOptions<any>): Observable<HttpResponse<AdpImportStatus>> {
		return this.http.get(this.buildUrl(options) + '/status', ['auth']);
	}
}
