import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AppGlobals } from '@app/core/common/app.globals';

@Injectable()
export class ManagerGuard implements CanActivate {

	constructor(private router: Router) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const user = AppGlobals.getLoggedUser();
		const hasSubordinates = user && user.subordinateIds && user.subordinateIds.length > 0;

		if (!hasSubordinates) {
			this.router.navigate(['/']);
		}

		return hasSubordinates;
	}
}
