import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppConfigService } from '@app/core/services/app-config.service';
import { AlertsService } from '@helloteaminc/front-common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { AppGlobals } from '../common/app.globals';
import { ErrorResponse } from '../common/error-response.model';
import { Queue } from '../common/queue';

declare let platform: any;

@Injectable({
	providedIn: 'root'
})
export class HttpInterceptor {

	lastRequests: Queue<{
		url: string,
		type: string,
		date: Date,
		headers: string[]
	}>;

	hasStatusCodeZeroError: boolean;

	constructor(
		private http: HttpClient,
		private modalService: BsModalService,
		private router: Router,
		private config: AppConfigService
	) {
		this.lastRequests = new Queue(30);

		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.hasStatusCodeZeroError = false;
			}
		});
	}

	public get(url: string, headers: string[], params?: HttpParams, skipGlobalErrors?: number[]): Observable<HttpResponse<any>> {
		this.addToRequestsQueue(url, 'GET', headers);

		const httpHeaders = this.getHttpHeaders(headers);
		const responseType: 'json' | 'blob' = headers.indexOf('blob') === -1 ? 'json' : 'blob';

		return this.http.get(url, {
			headers: httpHeaders,
			observe: 'response',
			responseType: <any>responseType,
			...params && {
				params: params
			}
		}).pipe(
			tap((response) => {
				this.onSuccess();
			}),
			catchError((response) => {
				this.onError(response, skipGlobalErrors);
				// Stop propagation of error in case of 403
				if (response.status === 403) {
					return EMPTY;
				}
				return throwError(response.error);
			})
		);
	}

	public post(url: string, body: any, headers: string[], skipGlobalErrors?: number[]): Observable<HttpResponse<any>> {
		this.addToRequestsQueue(url, 'POST', headers);

		const httpHeaders = this.getHttpHeaders(headers);
		const responseType: 'json' | 'blob' = headers.indexOf('blob') === -1 ? 'json' : 'blob';

		return this.http.post(url, body, {
			headers: httpHeaders,
			observe: 'response',
			responseType: <any>responseType
		}).pipe(
			tap((response) => {
				this.onSuccess();
			}),
			catchError((response) => {
				this.onError(response, skipGlobalErrors);
				// Stop propagation of error in case of 403
				if (response.status === 403) {
					return EMPTY;
				}
				return throwError(response.error);
			})
		);

	}

	public put(url: string, body: any, headers: string[], skipGlobalErrors?: number[]): Observable<HttpResponse<any>> {
		this.addToRequestsQueue(url, 'PUT', headers);

		const httpHeaders = this.getHttpHeaders(headers);

		return this.http.put(url, body, {
			headers: httpHeaders,
			observe: 'response'
		}).pipe(
			tap((response) => {
				this.onSuccess();
			}),
			catchError((response) => {
				this.onError(response, skipGlobalErrors);
				// Stop propagation of error in case of 403
				if (response.status === 403) {
					return EMPTY;
				}
				return throwError(response.error);
			})
		);
	}

	public patch(url: string, body: any, headers: string[], skipGlobalErrors?: number[]): Observable<HttpResponse<any>> {
		this.addToRequestsQueue(url, 'PATCH', headers);

		const httpHeaders = this.getHttpHeaders(headers);

		return this.http.patch(url, body, {
			headers: httpHeaders,
			observe: 'response'
		}).pipe(
			tap((response) => {
				this.onSuccess();
			}),
			catchError((response) => {
				this.onError(response, skipGlobalErrors);
				// Stop propagation of error in case of 403
				if (response.status === 403) {
					return EMPTY;
				}
				return throwError(response.error);
			})
		);
	}

	public delete(url: string, headers: string[], params?: HttpParams, skipGlobalErrors?: number[]): Observable<HttpResponse<any>> {
		this.addToRequestsQueue(url, 'DELETE', headers);

		const httpHeaders = this.getHttpHeaders(headers);

		return this.http.delete(url, {
			headers: httpHeaders,
			observe: 'response',
			...params && {
				params: params
			}
		}).pipe(
			tap((response) => {
				this.onSuccess();
			}),
			catchError((response) => {
				this.onError(response, skipGlobalErrors);
				// Stop propagation of error in case of 403
				if (response.status === 403) {
					return EMPTY;
				}
				return throwError(response.error);
			})
		);
	}

	public upload(url: string, file: File, skipGlobalErrors?: number[]): Observable<HttpEvent<any>> {

		const httpHeaders = this.getHttpHeaders(['auth']);
		let formData: FormData = new FormData();
		formData.append('file', file);

		return this.http.post(url, formData, {
			headers: httpHeaders,
			reportProgress: true,
			observe: 'events'
		}).pipe(
			catchError((response) => {
				this.onError(response, skipGlobalErrors);
				// Stop propagation of error in case of 403
				if (response.status === 403) {
					return EMPTY;
				}
				return throwError(response.error);
			})
		);
	}

	/**
	 * Get Http Headers depending on headers requested from HTTP calls
	 * @param {string[]} headers
	 * @returns {HttpHeaders}
	 */
	private getHttpHeaders(headers: string[]): HttpHeaders {
		let httpHeaders = new HttpHeaders();

		if (headers.indexOf('authentication') > -1) {
			const authenticationValue = headers[1]; // base64 encoded email:password
			const tokenResponse = headers[2];
			httpHeaders = this.attachAuthenticationLoginHeaders(httpHeaders, authenticationValue, tokenResponse);
		}

		if (headers.indexOf('authorization') > -1) {
			httpHeaders = this.attachAuthorizationHeaders(httpHeaders);
		}

		if (headers.indexOf('auth-with') > -1) {
			const token = headers[1];
			httpHeaders = this.attachAuthWithHeader(httpHeaders, token);
		}

		if (headers.indexOf('logger') > -1) {
			httpHeaders = this.attachLoggerHeaders(httpHeaders);
		}

		return httpHeaders;
	}

	private attachAuthenticationLoginHeaders(headers: HttpHeaders, encodedCredentials: string, tokenResponse: string): HttpHeaders {
		headers = headers.append('Authorization', `Basic ${encodedCredentials}`);
		headers = headers.append('x-requested-with', 'XMLHttpRequest'); // prevent default browser prompt for basic authentication
		headers = headers.append('tokenResponse', tokenResponse); // captcha token for basic authentication
		return headers;
	}

	private attachAuthorizationHeaders(headers: HttpHeaders): HttpHeaders {
		const clientId = this.config.getConfig().clientId;
		const clientSecret = this.config.getConfig().clientSecret;
		const hash = btoa(`${clientId}:${clientSecret}`);

		headers = headers.append('Authorization', `Basic ${hash}`);

		return headers;
	}

	private attachAuthWithHeader(headers: HttpHeaders, token: string): HttpHeaders {
		headers = headers.append('Authorization', `Bearer ${token}`);
		headers = headers.append('x-requested-with', 'XMLHttpRequest'); // prevent default browser prompt for basic authentication

		return headers;
	}

	private attachLoggerHeaders(headers: HttpHeaders): HttpHeaders {
		headers = headers.append('x-api-key', 'WjRlsls88t2Hy4EKBkB6919S0G1dewqi9L5uTzwl');
		return headers;
	}

	private onSuccess(): void {
		this.hasStatusCodeZeroError = false;
	}

	private onError(response: HttpErrorResponse, skipGlobalErrors?: number[]): void {
		if (response.status >= 500) {
			this.logError(response);
		}

		if (response.status === 0 && !this.hasStatusCodeZeroError) {
			const trackToken = response.headers.get('x-request-ref');
			let message = 'Couldn\'t connect to server. Please try again in a moment.';
			if (trackToken) {
				message = `${message} Error code '${trackToken}'`;
			}
			AlertsService.showErrorNotice('Error', message);
			this.hasStatusCodeZeroError = true;
			return;
		}

		if (response.status === 403) {
			if (!skipGlobalErrors || !skipGlobalErrors?.includes(403)) {
				let message = ErrorResponse.getFirstError(response.error)?.message;
				if (!message) message = response.error.message
				AlertsService.showErrorNotice('Error', message);

				this.closeAllModals();
				this.router.navigate(['/']);
				return;
			}
		}
	}

	private addToRequestsQueue(url: string, requestType: string, headers: string[]): void {
		this.lastRequests.push({
			url: url,
			date: new Date(),
			type: requestType,
			headers: headers
		});
	}

	private logError(response: HttpErrorResponse) {
		if (!this.config.getConfig().logErrors) {
			return;
		}

		if (response['_request']) {
			const requestBody = response['_request'].body;
			if (requestBody) {
				for (let key in requestBody) {
					if (requestBody.hasOwnProperty(key)) {
						if (key.toLowerCase().includes('pass')) {
							requestBody[key] = 'xxxxxxxxxxxxxxxxx';
						}
					}
				}
			}
		}

		const logData: any = {
			environment: this.config.getConfig().name,
			host: window.location.host,
			href: window.location.href,
			platform: platform.toString(),
			error: response
		};

		const loggedUser = AppGlobals.getLoggedUser();
		if (loggedUser) {
			logData.account = loggedUser;
		}

		logData.lastRequests = this.lastRequests.getItems();

		this.sendLog(logData);
	}

	private sendLog(logData: any): void {
		this.post('https://logger.helloteam.com', logData, ['logger']).pipe(
			take(1)
		).subscribe();
	}

	private closeAllModals() {
		for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
			this.modalService.hide(i);
		}
	}
}
