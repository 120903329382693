import { BaseModel } from '../../../core/interfaces/base.model';
import { Department } from '../../organization/departments/models/department.model';
import { EmploymentType } from '../../organization/employment-types/models/employment-type.model';
import { Location } from '../../organization/locations/models/location.model';
import { Position } from '../../organization/positions/models/position.model';
import { Picture } from '../../shared/models/picture.model';
import { TerminationInfo } from '../interfaces/termination-info.interface';
import { Tenure } from './tenure.model';

export class BaseUser implements BaseModel {
	id: string;
	name: string;
	email: string;
	profilePicture: Picture;
	dateHired: Date | string;
	terminationDate: Date | string;
	terminationInfo: TerminationInfo;
	terminated: boolean;
	department: Department;
	position: Position;
	reportsTo: BaseUser;
	location: Location;
	employmentType: EmploymentType;
	countryCode: string;
	tenure: Tenure;
	tenureDisplay: string;
	tenureOnPosition?: Tenure;
	tenureOnPositionDisplay?: string;
	phoneNumber: string;
	deleted?: boolean;

	constructor() {
		this.terminationInfo = {};
	}
}
