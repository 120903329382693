import { DateInterval } from '@app/core/interfaces/date-interval.interface';
import * as moment from 'moment';
import { Moment } from 'moment';
import { environment } from '../../../environments/environment';
import { AppGlobals } from '../common/app.globals';

export class DatetimeUtil {

	public static fromLocalDateToApiDateString(val: Date): string {
		if (!val) { return null; }

		const date = new Date(val);
		return moment(`${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(-2)}`).format(environment.apiDateFormat);
	}

	public static fromLocalDateToDateString(val: Date): string {
		if (!val) {
			return null;
		}

		return moment.utc(val).format(environment.dateFormat);
	}

	public static toMoment(val: string | Date | Moment, timeZone?: string): Moment {
		return moment(val, environment.apiDateTimeFormat).tz(timeZone || AppGlobals.getLoggedUser().preference.timeZone);
	}

	public static toMomentIgnoreTz(val: string | Date | Moment): Moment {
		return moment.utc(val, environment.apiDateTimeFormat);
	}

	public static toMomentIgnoreTzAndFormat(val: string | Date | Moment): Moment {
		return moment.utc(val);
	}

	public static toString(moment: Moment): string {
		return moment.format(environment.apiDateTimeFormat);
	}

	public static localNow(timeZone?: string): Moment {
		return moment.tz(timeZone || AppGlobals.getLoggedUser().preference.timeZone);
	}

	public static now(): string {
		return moment().format(environment.apiDateTimeFormat);
	}

	public static isBefore(date: string | Date | Moment, date2: string | Date | Moment): boolean {
		return moment(date).isBefore(date2);
	}

	public static isAfter(date: string | Date | Moment, date2: string | Date | Moment): boolean {
		return moment(date).isAfter(date2);
	}

	public static isBeforeNow(date: string | Date | Moment): boolean {
		const checkDate = moment(date).tz(AppGlobals.getLoggedUser().preference.timeZone);
		const today = DatetimeUtil.localNow().startOf('day');
		return checkDate.isBefore(today);
	}

	public static isSameDay(date1: Moment, date2?: Moment): boolean {
		if (!date2) {
			date2 = DatetimeUtil.localNow();
		}

		return date1.isSame(date2, 'd');
	}

	public static isTomorrow(date: Moment): boolean {
		return date.isSame(DatetimeUtil.localNow().add(1, 'd'), 'd');
	}

	public static isSameWeek(date1: Moment, date2?: Moment): boolean {
		if (!date2) {
			date2 = DatetimeUtil.localNow();
		}

		const days = date2.endOf('isoWeek').diff(date1, 'days');
		return days >= 0 && days < 6;
	}

	public static isUpcoming(date: Moment): boolean {
		return moment().endOf('isoWeek').diff(date, 'days') > 6;
	}

	public static isPast(date: Moment): boolean {
		return moment(date).diff(DatetimeUtil.localNow(), 'days') < 0;
	}

	public static getYear(): number {
		return +moment().format('YYYY');
	}

	public static getQuarter(): number {
		return moment().quarter();
	}

	public static getMonth(): number {
		return +moment().format('M');
	}

	public static getLatestDate(dates: Date[]): Date {
		return new Date(Math.max.apply(null, dates));
	}

	public static getLastMonthName(): string {
		return moment().subtract(1, 'month').format('MMMM');
	}

	public static getPrevMonthName(): string {
		return moment().subtract(2, 'month').format('MMMM');
	}

	/**
	 * Returns date format for the current client browser settings
	 * @param simplified - remove separators and repetitive letters in date format
	 * @returns {string}
	 */
	public static getDateFormatByLocation(simplified: boolean): string {
		let locale = window.navigator.language;
		moment.locale(locale);

		let format = moment.localeData().longDateFormat('L');

		if (simplified) {
			format = format.replace(/\W+/g, ''); // remove separators
			format = format.replace(/(.)(?=.*\1)/g, ''); // remove repeating letters
			format = format.toLowerCase();
		}

		return format;
	}

	public static timeSince(datetime: Moment): string {
		const seconds = moment().diff(datetime, 'seconds');
		if (seconds < 60) {
			return 'just now';
		}

		const minutes = moment().diff(datetime, 'minutes');
		if (minutes === 1) {
			return '1 minute ago';
		}
		if (minutes < 60) {
			return minutes + ' minutes ago';
		}

		const hours = moment().diff(datetime, 'hours');
		if (hours === 1) {
			return '1 hour ago';
		}
		if (hours < 24) {
			return hours + ' hours ago';
		}

		return datetime.format(environment.dateTimeFormat);
	}

	public static getThisMonthInterval(format?: string): DateInterval {
		return {
			startDate: moment().date(1).format(format || 'YYYY-MM-DD'),
			endDate: moment().add(1, 'day').format(format || 'YYYY-MM-DD')
		};
	}

	public static getMonthsBackInterval(monthsCount: number, format?: string): DateInterval {
		return {
			startDate: moment().subtract(monthsCount, 'months').date(1).format(format || 'YYYY-MM-DD'),
			endDate: moment().date(1).format(format || 'YYYY-MM-DD')
		};
	}

	public static getLastMonthInterval(format?: string): DateInterval {
		return DatetimeUtil.getMonthsBackInterval(1, format);
	}

	public static getPreviousMonthInterval(format?: string): DateInterval {
		return {
			startDate: moment().subtract(2, 'months').date(1).format(format || 'YYYY-MM-DD'),
			endDate: moment().subtract(1, 'months').date(1).format(format || 'YYYY-MM-DD')
		};
	}

	public static getThisQuarterInterval(format?: string): DateInterval {
		return {
			startDate: moment().startOf('quarter').format(format || 'YYYY-MM-DD'),
			endDate: moment().add(1, 'day').format(format || 'YYYY-MM-DD')
		};
	}

	public static getQuartersBackInterval(quartersCount: number, format?: string): DateInterval {
		return {
			startDate: moment().subtract(quartersCount, 'quarter').startOf('quarter').format(format || 'YYYY-MM-DD'),
			endDate: moment().startOf('quarter').format(format || 'YYYY-MM-DD')
		};
	}

	public static getLastQuarterInterval(format?: string): DateInterval {
		return this.getQuartersBackInterval(1, format);
	}

	public static getPreviousQuarterInterval(format?: string): DateInterval {
		return {
			startDate: moment().subtract(2, 'quarter').startOf('quarter').format(format || 'YYYY-MM-DD'),
			endDate: moment().subtract(1, 'quarter').startOf('quarter').format(format || 'YYYY-MM-DD')
		};
	}

	public static getThisYearToTodayInterval(format?: string): DateInterval {
		return {
			startDate: moment().month(0).date(1).format(format || 'YYYY-MM-DD'),
			endDate: moment().add(1, 'day').format(format || 'YYYY-MM-DD')
		};
	}

	public static getYearsBackInterval(yearsCount: number, format?: string): DateInterval {
		return {
			startDate: moment().subtract(yearsCount, 'years').month(0).date(1).format(format || 'YYYY-MM-DD'),
			endDate: moment().add(1, 'day').format(format || 'YYYY-MM-DD')
		};
	}

	public static getExactYearsBackInterval(yearsCount: number, format?: string): DateInterval {
		return {
			startDate: moment().subtract(yearsCount, 'years').add(1, 'day').format(format || 'YYYY-MM-DD'),
			endDate: moment().add(1, 'day').format(format || 'YYYY-MM-DD')
		};
	}

	public static getLastYearInterval(format?: string): DateInterval {
		return {
			startDate: moment().subtract(1, 'years').month(0).date(1).format(format || 'YYYY-MM-DD'),
			endDate: moment().month(0).date(1).format(format || 'YYYY-MM-DD')
		};
	}

	public static getThisYearInterval(format?: string): DateInterval {
		return {
			startDate: moment().month(0).date(1).format(format || 'YYYY-MM-DD'),
			endDate: moment().date(1).format(format || 'YYYY-MM-DD')
		};
	}

	public static getPreviousYearInterval(format?: string): DateInterval {
		return {
			startDate: moment().subtract(2, 'years').month(0).date(1).format(format || 'YYYY-MM-DD'),
			endDate: moment().subtract(1, 'years').month(0).date(1).format(format || 'YYYY-MM-DD')
		};
	}

	public static getCurrentFormatAsPipeFormat(): string {
		let date = environment.dateFormat.split(' ');
		return date[0].toLowerCase() + ' ' + date[1] + ' ' + date[2].toLowerCase(); // dd MMM yyyy
	}

	public static transformToApiFormat(date: string): string {
		return moment(date, environment.dateTimeFormat).format(environment.apiDateTimeFormat);
	}

	public static isValidDate(val: any): boolean {
		return moment(val).isValid();
	}

	public static localStartOfDay(val: string | Date | Moment): string {
		return moment(val).startOf('day').format(environment.apiDateTimeFormat);
	}

	public static localEndOfDay(val: string | Date | Moment): string {
		return moment(val).endOf('day').format(environment.apiDateTimeFormat);
	}

	public static localDate(val: string | Date | Moment) {
		return moment(val).format(environment.apiDateTimeFormat);
	}

	public static sortAscending(a: string | Date | Moment, b: string | Date | Moment): number {
		let aMoment = moment(a);
		let bMoment = moment(b);

		return aMoment.isAfter(bMoment) ? 1 : aMoment.isBefore(bMoment) ? -1 : 0;
	}

	public static sortDescending(a: string | Date | Moment, b: string | Date | Moment): number {
		let aMoment = moment(a);
		let bMoment = moment(b);

		return aMoment.isBefore(bMoment) ? 1 : aMoment.isAfter(bMoment) ? -1 : 0;
	}

	public static getMostRecentPeriod(periods: { end: string, id: string }[]): { end: string, id: string } {
		if (!periods || !periods.length) {
			return null;
		}

		let mostRecent = periods[0];
		let smallestDifference = Number.MIN_SAFE_INTEGER;
		let now = moment();

		for (let i = 0; i < periods.length; i += 1) {
			let currentDifference = moment(periods[i].end).diff(now);

			if (currentDifference > smallestDifference && currentDifference < 1) {
				smallestDifference = currentDifference;
				mostRecent = periods[i];
			}
		}

		return mostRecent;
	}

	public static areSame(dateA: string | Date | Moment, dateB: string | Date | Moment): boolean {
		return moment.utc(dateA).isSame(moment.utc(dateB));
	}

	public static toIsoStringIgnoreTz(date: Date | string | Moment): string {
		const zoneId = AppGlobals.getLoggedUser().preference.timeZone;
		const zone = moment.tz.zone(zoneId);
		const dateObject = moment(date).toDate();
		let offset = null;
		if (zone) {
			offset = zone.parse(
				Date.UTC(
					dateObject.getFullYear(),
					dateObject.getMonth(),
					dateObject.getDate(),
					dateObject.getHours(),
					dateObject.getMinutes(),
					dateObject.getSeconds(),
					dateObject.getMilliseconds()
				));
		}
		return moment.utc(date).subtract(offset, 'minute').toISOString();
	}

	public static moment(date: Date | string | Moment): Moment {
		return moment(date);
	}
}
