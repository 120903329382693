import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { fromEvent, Observable } from 'rxjs';
import { AuthenticationService } from '@app/core/services/authentication.service';
import { ManagerOptions } from 'socket.io-client/build/esm/manager';
import { Socket, SocketOptions } from 'socket.io-client/build/esm/socket';

@Injectable()
export class SocketIoService {
	private socket: Socket;
	private socketOptions: Partial<ManagerOptions & SocketOptions> = {
		path: '/ws/socket.io/',
		transportOptions: {
			polling: {
				extraHeaders: {
					Authorization: `Bearer ${this.authService.getAccessToken()}`
				}
			}
		}
	};

	constructor(private authService: AuthenticationService) {}

	public connect() {
		if (this.socket) {
			this.disconnect();
		}
		this.socket = io('/', this.socketOptions);
	}

	public disconnect() {
		if (this.socket) {
			this.socket.disconnect();
			this.socket = null;
		}
	}

	fetchNotifications(payload: any) {
		this.socket.emit('n.fetch', payload);
	}

	onNotificationFetch() {
		return fromEvent(this.socket, 'n.fetch');
	}

	onNotificationCount(): Observable<number> {
		this.socket.emit('n.count');
		return fromEvent(this.socket, 'n.count');
	}
}
