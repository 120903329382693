import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppGlobals } from '../../common/app.globals';

@Injectable()
export class DashboardGuard implements CanActivate {

	constructor(private router: Router) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		const components = AppGlobals.dashboardComponents;
		if (AppGlobals.hasAnyComponent(components)) {
			return true;
		}

		this.router.navigate(['profile', AppGlobals.getLoggedUser().id]);
		return false;
	}
}
