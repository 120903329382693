import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { HttpInterceptor } from '../../../../core/http/http.interceptor';
import { RequestOptions } from '../../../../core/interfaces/request-options.interface';
import { CrudUsersContainingService } from '../../../../core/services/crud-user-containing.service';
import { UserGroup } from '../models/user-group.model';
import { environment } from './../../../../../environments/environment.production';
import { User } from './../../../users/models/user.model';
import { UserGroupEmployeeSourceOptions } from './../models/user-group-employee-source-options.model';

@Injectable({
	providedIn: 'root'
})
export class UserGroupsService extends CrudUsersContainingService<UserGroup, RequestOptions<UserGroup>> {
	employeeSourceOptions$: Observable<UserGroupEmployeeSourceOptions | null>;

	private employeeSourceOptionsSubject: BehaviorSubject<UserGroupEmployeeSourceOptions | null> =
		new BehaviorSubject<UserGroupEmployeeSourceOptions | null>({
			departmentIds: [],
			locationIds: [],
			positionIds: [],
			employmentTypeIds: [],
			userIds: [],
			excludedUserIds: []
		});
	constructor(http: HttpInterceptor) {
		super(http, '/user-groups');

		this.employeeSourceOptions$ = this.employeeSourceOptionsSubject.asObservable().pipe(filter((value) => !!value));
	}

	importCsv(file: File): Observable<HttpResponse<any>> {
		const formData = new FormData();
		formData.append('file', file, file.name);

		return this.http.put(this.buildUrl({}, '/csv'), formData, ['auth']);
	}

	exportCsv(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/download/csv'), options.data, ['auth', 'blob']);
	}

	getSetsOfEmployees$(options: UserGroupEmployeeSourceOptions): Observable<User[]> {
		return this.http.post(this.buildUrl({}, '/filter'), { ...options }, ['auth']).pipe(
			map((response) => {
				const body = response.body.filter((e) => !e.terminated);

				body.forEach((employee) => {
					User.updatePicture(employee);
				});

				return body;
			})
		);
	}

	getEmployeeSourceOptions(): UserGroupEmployeeSourceOptions {
		return this.employeeSourceOptionsSubject.getValue();
	}

	setEmployeeSourceOptions(options: UserGroupEmployeeSourceOptions): void {
		return this.employeeSourceOptionsSubject.next({
			...this.getEmployeeSourceOptions(),
			...options
		});
	}

	clearEmployeeSourceOptions(): void {
		return this.employeeSourceOptionsSubject.next(null);
	}
}
