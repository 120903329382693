import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpInterceptor } from '../http/http.interceptor';
import { BaseModel } from '../interfaces/base.model';
import { PagedResourceInterface } from '../interfaces/paged-resource.interface';
import { RequestOptions } from '../interfaces/request-options.interface';
import { CrudService } from './crud.service';

export abstract class CrudTempService<T extends BaseModel, O extends RequestOptions<T>> extends CrudService<T, O> {

	constructor(http: HttpInterceptor, collection: string) {
		super(http, collection);
	}

	public getConsolidationUri(): string {
		return this.getUri() + '/consolidation?expand=imported';
	}

	public getAll(options: O, suffix?: string): Observable<HttpResponse<PagedResourceInterface<T>>> {
		this.addExpands(options);
		return super.getAll(options, suffix);
	}

	public getById(options: O): Observable<HttpResponse<T>> {
		this.addExpands(options);
		return super.getById(options);
	}

	public save(options: O): Observable<HttpResponse<T>> {
		this.addExpands(options);
		return super.save(options);
	}

	public patch(options: O): Observable<HttpResponse<T>> {
		this.addExpands(options);
		return super.patch(options);
	}

	protected addExpands(options: O): void {
		if (!options.expand) {
			options.expand = [];
		}

		if (options.expand.indexOf('imported') === -1) {
			options.expand.push('imported');
		}
	}
}
