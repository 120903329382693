import { EventEmitter, Injectable } from '@angular/core';
import { MessageTypeEnum } from '../../shared/components/messages/enums/message-type.enum';
import { Message } from '../../shared/components/messages/models/message.model';

@Injectable({
	providedIn: 'root'
})
export class MessagesService {

	eventEmitterMessage = new EventEmitter<Message[]>();

	messages: Message[];

	removeMessages() {
		this.setMessages([]);
	}

	setSuccessMessages(messages: string[]) {
		this.setMessages(this.mapToMessages(messages, MessageTypeEnum.Success));
	}

	setErrorMessages(messages: string[]) {
		this.setMessages(this.mapToMessages(messages, MessageTypeEnum.Error));
	}

	setWarningMessage(messages: string[]) {
		this.setMessages(this.mapToMessages(messages, MessageTypeEnum.Warning));
	}

	setInfoMessage(messages: string[]) {
		this.setMessages(this.mapToMessages(messages, MessageTypeEnum.Info));
	}

	popMessages(): Message[] {
		const messages = this.messages;
		this.messages = [];

		return messages;
	}

	hasMessages() {
		return (this.messages && this.messages.length) || false;
	}

	private setMessages(messages: Message[]) {
		this.messages = messages;
		this.eventEmitterMessage.emit(messages);
	}

	private mapToMessages(messages: string[], type: MessageTypeEnum): Message[] {
		return messages.map<Message>(m => new Message(m, type));
	}
}
