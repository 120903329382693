import { HierarchyPermissionsEnum } from '@app/main/permissions/enums/hierarchy-permissions.enum';
import { UserHierarchicalPermissions } from '@app/main/permissions/models/user-hierarchical-permissions.model';
import { Picture } from '@app/main/shared/models/picture.model';
import { User } from '@app/main/users/models/user.model';
import * as moment from 'moment-timezone';
import { BehaviorSubject, Subject } from 'rxjs';
import { Changes } from './changes.model';

export class AppGlobals {

	public static loggedUserSubject: BehaviorSubject<User> = new BehaviorSubject<User>(null);
	public static hasPendingChangesSubject: BehaviorSubject<Changes> = new BehaviorSubject<Changes>(new Changes(false, ''));
	public static loggedUserHierarchicalPermissions: BehaviorSubject<UserHierarchicalPermissions> = new BehaviorSubject<UserHierarchicalPermissions>(null);

	public static personalAvatarUpdatedSubject = new Subject<Picture>();
	public static otherAvatarUpdatedSubject = new Subject<Picture>();

	public static dashboardFiltersSubject = new BehaviorSubject<{ [key: string]: string }>({});

	/**
	 * Field definitions based on /custom-fields/filter
	 * Fetched on main module load and used to determine if custom fields UI elements should be displayed
	 */
	public static fieldDefinitions$ = new BehaviorSubject<string[]>([]);

	public static performanceReviewSubject = new Subject<number>();
	public static surveySubject = new Subject<number>();
	public static goalsConfigSubject = new Subject<string>();

	public static compensationSubject = new Subject<number>();

	public static dashboardComponents = ['NEW_EMPLOYEES', 'ANNIVERSARIES', 'BIRTHDAYS', 'JOB_CHANGES', 'HIGH_FIVES', 'PERFORMANCE_REVIEWS', 'PERFORMANCE_REVIEWS_V2', 'SURVEYS', 'USEFUL_LINKS', 'NEWS', 'EVENTS'];

	public static getLoggedUseHierarchicalPermissions(): UserHierarchicalPermissions {
		return AppGlobals.loggedUserHierarchicalPermissions.getValue();
	}

	public static getLoggedUser(): User {
		return AppGlobals.loggedUserSubject.getValue();
	}

	public static setLoggedUserHierarchicalPermissions(userPermissions: UserHierarchicalPermissions): void {
		AppGlobals.loggedUserHierarchicalPermissions.next(userPermissions);
	}

	public static setLoggedUser(user: User): void {
		if (!user.components) {
			user.components = [];
		}

		if (user.preference) {
			moment.tz.setDefault(user.preference.timeZone);
		}

		AppGlobals.loggedUserSubject.next(user);
	}

	public static updateLoggedUser(): void {
		this.loggedUserSubject.next(this.loggedUserSubject.getValue());
	}

	public static hasHierarchyComponent(category: string, component: string, userId: string): boolean {
		const loggedUserHierarchies = AppGlobals.getLoggedUseHierarchicalPermissions();

		if (!loggedUserHierarchies) {
			return false;
		}

		let hasComponent = loggedUserHierarchies.permissions.includes(`${category}:${component}`);

		if (!hasComponent) {
			return false;
		}

		switch (category) {
			case HierarchyPermissionsEnum.DIRECT_MANAGER:
				return loggedUserHierarchies.directReportees.includes(userId);
			case HierarchyPermissionsEnum.MANAGERS_MANAGER:
				return loggedUserHierarchies.secondTierReportees.includes(userId);
			case HierarchyPermissionsEnum.INFINITY_UP:
				return loggedUserHierarchies.infinityUp.includes(userId);
			default:
				return false;
		}
	}

	public static hasAnyHierarchyComponent(component: string, userId: string): boolean {
		return AppGlobals.hasHierarchyComponent(HierarchyPermissionsEnum.DIRECT_MANAGER, component, userId) ||
			AppGlobals.hasHierarchyComponent(HierarchyPermissionsEnum.MANAGERS_MANAGER, component, userId) ||
			AppGlobals.hasHierarchyComponent(HierarchyPermissionsEnum.INFINITY_UP, component, userId);
	}

	/**
	 * Check if logged in user has component or is accessing own resource
	 * @param {string} component - Key of component
	 * @param {string} userId - userId whose resource is being accessed
	 * @returns {boolean}
	 */
	public static hasComponent(component: string, userId?: string): boolean {
		const loggedUser = AppGlobals.getLoggedUser();

		if (!loggedUser) {
			return false;
		}

		let hasComponent = AppGlobals.getLoggedUser().components.indexOf(component) > -1;

		if (!hasComponent && userId) {
			hasComponent = hasComponent || AppGlobals.isLoggedInUser(userId);
		}

		return hasComponent;
	}

	public static hasComponentAndUser(component: string, userId?: string): boolean {
		const loggedUser = AppGlobals.getLoggedUser();

		if (!loggedUser) {
			return false;
		}

		let hasComponent = AppGlobals.getLoggedUser().components.indexOf(component) > -1;

		if (hasComponent && userId) {
			hasComponent = hasComponent && AppGlobals.isLoggedInUser(userId);
		}

		return hasComponent;
	}

	/**
	 * Check if logged in user has any of components or is accessing own resource
	 * @param {string} components - Key of components
	 * @param {string} userId - userId whose resource is being accessed
	 * @returns {boolean}
	 */
	public static hasAnyComponent(components: string[], userId?: string): boolean {
		let hasComponent = false;

		components.forEach((component) => {
			const check = AppGlobals.hasComponent(component, userId);
			if (check) {
				hasComponent = true;
				return;
			}
		});

		return hasComponent;
	}

	public static isLoggedInUser(userId: string): boolean {
		return userId === AppGlobals.getLoggedUser().id;
	}

	public static setPendingChangesValue(value: Changes): void {
		AppGlobals.hasPendingChangesSubject.next(value);
	}

	public static updateDashboardFilters(filters: { [key: string]: string }): void {
		this.dashboardFiltersSubject.next(filters);
	}

	public static getDashboardFilters(): { [key: string]: string } {
		return this.dashboardFiltersSubject.getValue();
	}

	public static environmentTag(tag: string): string {
		let tagName;
		switch (tag) {
			case 'adp':
				tagName = 'ADP_Surveys';
				break;
			case 'production':
				tagName = 'HT_Production';
				break;
			case 'staging':
				tagName = 'HT_Staging';
				break;
			case 'staging2':
				tagName = 'HT_Staging2';
				break;
			case 'demo':
				tagName = 'HT_Demo';
				break;
			case 'dev':
				tagName = 'HT_Dev';
				break;
			case 'testing':
				tagName = 'HT_Testing';
				break;
			default:
				tagName = '';
		}
		return tagName;
	}
}
