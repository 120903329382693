export class ValidationService {

	validationRules: { [name: string]: any } = {
		'email': {
			emailValidation: true
		},
		'password': {
			passwordValidation: true,
			passwordCharsRepeatValidation: true
		},
		'rePassword': {
			passwordValidation: true,
			passwordCharsRepeatValidation: true,
			equalTo: '#password'
		},
		'phoneNumber': {
			phoneValidation: true,
		},
		'mapLink': {
			googleMapDomainValidation: true
		},
		'domain': {
			domainValidation: true
		},
		'link': {
			urlValidation: true
		},
		'event-publish-date': {
			isEventPublishDateBefore: true
		},
		'end-event-datepicker': {
			isEventEndDateAfter: true
		},
		'weekly-day': {
			required: '#de-weekly:checked'
		},
		'weekly-time': {
			required: '#de-weekly:checked'
		},
		'weekly-timezone': {
			required: '#de-weekly:checked'
		},
		'monthly-day': {
			required: '#de-monthly:checked'
		},
		'monthly-time': {
			required: '#de-monthly:checked'
		},
		'monthly-timezone': {
			required: '#de-monthly:checked'
		},
		'goal-weekly-day': {
			required: '#de-goal-weekly:checked'
		},
		'goal-weekly-time': {
			required: '#de-goal-weekly:checked'
		},
		'goal-weekly-timezone': {
			required: '#de-goal-weekly:checked'
		},
		'goal-monthly-day': {
			required: '#de-goal-monthly:checked'
		},
		'goal-monthly-time': {
			required: '#de-goal-monthly:checked'
		},
		'goal-monthly-timezone': {
			required: '#de-goal-monthly:checked'
		}
	};

	validationRulesMessages: { [name: string]: any } = {};

	public static getPhoneNumberRegex(): RegExp {
		return /^((?:(\(\+?[0-9 ]+\))|(\+\(?[0-9 ]+\))|(?:\+?[0-9]+ \([0-9 ]+\))|(\+?[0-9 ]*))(?:([0-9 ])|([ ]*\([0-9 ]\)))[0-9- ]*)(?:[0-9])$/i;
	}

	public static sanitizeHTMLEntities(input: string): string {
		if (!input) {
			return '';
		}

		return input.replace(/[\u00A0-\u9999<>\&]/gim, (i) => {
			return '&#' + i.charCodeAt(0) + ';';
		});
	}

	public static unsanitizeHTMLEntities(input: string): string {
		return input.replace(/&#(\d+);/gim, (i, group) => {
			return String.fromCharCode(Number.parseInt(group, 10));
		});
	}

	public isImageFile(file: any, svgAllowed?: boolean): boolean {
		if (svgAllowed) {
			if (file.type) {
				return /^image\/\w+(\+\w+)?$/.test(file.type);
			}

			return /\.(jpg|jpeg|png|svg)$/.test(file);
		} else {
			if (file.type) {
				return /^image\/\w+$/.test(file.type);
			}

			return /\.(jpg|jpeg|png)$/.test(file);
		}
	}

	isValid(formId: any): boolean {
		if (typeof formId === 'string' && !formId.startsWith('#')) {
			formId = '#' + formId;
		}

		return ($(formId) as any).valid();
	}

	validate(formId: any, fields?: string[]): JQueryValidation.Validator {
		const currentValidationRules: { [name: string]: any } = {};
		const currentValidationRulesMessages: { [name: string]: any } = {};

		if (fields) {
			for (let i = 0, j = fields.length; i < j; i++) {
				currentValidationRules[fields[i]] = this.validationRules[fields[i]];
				currentValidationRulesMessages[fields[i]] = this.validationRulesMessages[fields[i]];
			}
		}

		if (typeof formId === 'string' && !formId.startsWith('#')) {
			formId = '#' + formId;
		}

		return ($(formId) as any).validate({
			rules: currentValidationRules,
			messages: currentValidationRulesMessages,
			ignore: '',
			errorClass: 'label-error',
			errorElement: 'label',
			errorPlacement: function (error, element) {
				const $parent = $(element).parent();
				if ($parent.hasClass('input-group')) {
					error.insertAfter($parent);
				} else {
					$parent.append(error);
				}
			}
		});
	}

	showErrorLabel(formId: any, inputName: string, errorText: string): void {
		if (typeof formId === 'string' && !formId.startsWith('#')) {
			formId = '#' + formId;
		}

		const $validator = ($(formId) as any).validate();
		$validator.showErrors({ [inputName]: errorText });
	}

	showErrorLabels(formId: any, errors: any, skipKeys?: string[], errorProp?: string): void {
		if (typeof formId === 'string' && !formId.startsWith('#')) {
			formId = '#' + formId;
		}

		const $validator = ($(formId) as any).validate();

		for (let key in errors) {
			if (errors.hasOwnProperty(key) && typeof errors[key] === 'object') {
				const message = errorProp ? errors[key][0][errorProp] : errors[key][0]['message'];
				if (!skipKeys || skipKeys.indexOf(key) === -1) {
					$validator.showErrors({
						[key]: message
					});
				}
			}
		}
	}

	removeErrorLabel(inputSelector: any): void {
		const $elem: any = $(inputSelector);
		$elem.removeClass('label-error');
		$elem.find('input').removeClass('label-error'); // if init on group, no on input itself
		$elem.siblings('.label-error').remove();
	}
}
