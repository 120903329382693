export enum HierarchyPermissionsEnum {
	GOALS = 'GOALS_PRIVILEGE',
	GOALS_VIEW_PRIVATE = 'GOALS_VIEW_PRIVATE_GOALS_PRIVILEGE',
	GOALS_MODIFY = 'GOALS_MODIFY_OTHER_PRIVILEGE',
	PROFILES = 'PROFILES_PRIVILEGE',
	PROFILES_VIEW_DOCUMENTS = 'USER_DOCUMENTS_PRIVILEGE',
	PROFILES_MODIFY_DOCUMENTS = 'USER_DOCUMENTS_MODIFY_OTHER_PRIVILEGE',
	ONE_ON_ONES = 'VIEW_ONE_ON_ONE_NOTES',
	FIELDS = 'USERS_MODIFY_PRIVILEGE',

	DIRECT_MANAGER = 'DIRECT_MANAGER',
	MANAGERS_MANAGER = 'MANAGERS_MANAGER',
	INFINITY_UP = 'INFINITY_UP'
}
