import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HierarchiesService } from '../../../main/permissions/services/hierarchies.service';

@Injectable({
	providedIn: 'root'
})
/**
 * Resolve user subordinates in a guard because we need it before the MainModule stars loading
 * Resolver doesn't work in this case because of the `canActivate` and `canLoad` guards inside of the MainModule
 */
export class SubordinatesGuard implements CanLoad {

	constructor(private hierarchiesService: HierarchiesService) { }

	canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
		return this.hierarchiesService.requestHierarchicalPermissions().pipe(
			map(response => !!response)
		);
	}
}
