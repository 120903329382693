export class UserSocialLinks {
	linkedIn: string;
	twitter: string;
	skype: string;
	facebook: string;
	pinterest: string;
	instagram: string;
	slackWorkspace: string;
	keybase: string;
	whatsApp: string;
	zoom: string;
	microsoftTeams: string;
}
