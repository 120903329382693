import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { catchError, distinctUntilChanged, take } from 'rxjs/operators';

interface ProjectVersion {
	version: string;
	hash: string;
}

@Injectable({
	providedIn: 'root'
})
export class VersionCheckService {
	readonly currentVersion = new BehaviorSubject<string>('').pipe(distinctUntilChanged());

	// this will be replaced by actual hash post-build.js
	private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
	constructor(private http: HttpClient) {
	}

	/**
	 * Checks if hash has changed.
	 * This file has the JS hash, if it is a different one than in the version.json
	 * we are dealing with version change
	 * @param currentHash - the hash that was defined previously
	 * @param newHash - the hash that has changed
	 */
	private static hasHashChanged(currentHash: string, newHash: string): boolean {
		if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
			return false;
		}

		return currentHash !== newHash;
	}

	/**
	 * Checks in every set frequency the version of frontend application
	 * @param url - url to the version file
	 * @param frequency - in milliseconds, defaults to 30 minutes
	 */
	public initVersionCheck(url: string, frequency = 1000 * 60 * 30): void {
		this.checkVersion(url);
		setInterval(() => {
			this.checkVersion(url);
		}, frequency);
	}

	/**
	 * Will do the call and check if the hash has changed or not
	 * @param url - url to the version file
	 */
	private checkVersion(url: string): void {
		// timestamp these requests to invalidate caches
		this.http
			.get<ProjectVersion>(`${url}?t=${new Date().getTime()}`)
			.pipe(
				take(1),
				catchError(() => {
					console.error('catchError checkVersion');

					return EMPTY;
				})
			)
			.subscribe(
				(response: ProjectVersion) => {
					(this.currentVersion as BehaviorSubject<string>).next(response.version);
					const hash = response.hash;
					const hashChanged = VersionCheckService.hasHashChanged(this.currentHash, hash);

					// If new version, do something
					if (hashChanged) {
						// ENTER YOUR CODE TO DO SOMETHING UPON VERSION CHANGE
						// for an example: location.reload();
						window.location.reload();
					}
					// store the new hash so we wouldn't trigger versionChange again
					// only necessary in case you did not force refresh
					// this.currentHash = hash;
				},
				(err) => {
					console.error(err, 'Could not get version');
				}
			);
	}
}
