import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpInterceptor } from '../../core/http/http.interceptor';
import { RequestOptions } from '../../core/interfaces/request-options.interface';
import { BaseService } from '../../core/services/base.service';

@Injectable()
export class AdpAuthService extends BaseService<any, RequestOptions<any>> {

	constructor(http: HttpInterceptor) {
		super(http, '/auth/adp');
	}

	getLocation(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options), []);
	}

	signIn(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options), []);
	}

	hashLogin(options: RequestOptions<any>, hash: string): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options) + '/hash/' + hash, []);
	}
}
