import { HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppGlobals } from '@app/core/common/app.globals';
import { SurveySettingsBase } from '@app/main/queries/common/models/survey-settings-base.model';
import { OrganizationSetup } from '@app/register/models/organization-setup.model';
import { RegisterInfo } from '@app/register/models/register-info.model';
import { Angulartics2 } from 'angulartics2';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpInterceptor } from '../http/http.interceptor';
import { RequestOptions } from '../interfaces/request-options.interface';
import { BaseService } from './base.service';

@Injectable()
export class OrganizationsService extends BaseService<any, RequestOptions<any>> {

	constructor(http: HttpInterceptor, private angulartics2: Angulartics2) {
		super(http, '/organizations');
	}

	getData(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options) + '/current', ['auth']);
	}

	checkInitialSetup$(): Observable<{ initialSetup: boolean }> {
		const hasAccessToGeneralSettings = AppGlobals.hasComponent('ORG_SETTINGS_GENERAL');

		if (!hasAccessToGeneralSettings) {
			return of({ initialSetup: true });
		}

		return this.http.get(this.buildUrl({}, '/initial-setup'), ['auth'], null, [403]).pipe(
			map(response => response.body),
			catchError(() => of({ initialSetup: true }))
		);
	}

	getAdmins(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options, '/current/contact/admins'), ['auth']);
	}

	contactAdmins(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/current/contact/admins'), options.data, ['auth']);
	}

	contactSupport(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/current/contact/support'), options.data, ['auth']);
	}

	setInvitationExpiration(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/invitation-token-expiry'), options.data, ['auth']);
	}

	getNumberOfEmployeesRanges(): Observable<any> {
		const url = environment.apiUrl + '/number-of-employees';
		return this.http.get(url, ['auth']).pipe(
			map((response) => response.body.sort((a, b) => a.range.match(/[0-9]+/) - b.range.match(/[0-9]+/)))
		);
	}

	getRegisterInfo$(token: string): Observable<RegisterInfo> {
		const params = new HttpParams({
			fromObject: {
				token: token
			}
		});

		const url = environment.apiUrl + '/internal/organizations/register/info';
		return this.http.get(url, [], params).pipe(
			map(response => response.body)
		);
	}

	setupOrganization$(body: OrganizationSetup): Observable<HttpResponse<any>> {
		const url = environment.apiUrl + '/internal/auth/setup/organization';
		return this.http.post(url, body, []);
	}

	setCountryCode(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/country-code'), options.data, ['auth']);
	}

	setNumberOfEmployees(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/number-of-employees'), options.data, ['auth']);
	}

	getIndustry(): Observable<HttpResponse<any>> {
		const url = environment.apiUrl + '/industry';
		return this.http.get(url, ['auth']);
	}

	setIndustry(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/industry'), options.data, ['auth']);
	}

	includeInactiveBySearch(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/search-inactive-employees'), options.data, ['auth']);
	}

	checkDomain(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options, '/domain/available'), []);
	}

	getDomainSuggestions(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options, '/domain'), []);
	}

	getDigestSections(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options, '/current/digest-sections'), ['auth']);
	}

	setDigestSections(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/current/digest-sections'), options.data, ['auth']);
	}

	getFeatures(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options, '/features'), ['auth']);
	}

	setFeatures(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/features'), options.data, ['auth']);
	}

	checkBenchmarkFeature(): Observable<boolean> {
		return this.http.get(this.buildUrl({}, '/features/benchmarks-enabled'), ['auth']).pipe(
			map((response) => response.body.result)
		);
	}

	getSocialLinks(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options, '/social-links'), ['auth']);
	}

	setSocialLinks(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/social-links'), options.data, ['auth']);
	}

	getHighFiveSettings(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options, '/high-five-settings'), ['auth']);
	}

	setHighFiveSettings(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.put(this.buildUrl(options, '/high-five-settings'), options.data, ['auth']);
	}

	getSurveysSettings(options: RequestOptions<any>): Observable<HttpResponse<SurveySettingsBase>> {
		return this.http.get(this.buildUrl(options, '/survey-settings'), ['auth']);
	}

	setSurveysSettings(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/survey-settings'), options.data, ['auth']);
	}

	setFiscalYear(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/current/fiscal-year-start'), options.data, ['auth'])
			.pipe(
				tap({
					next: () => {
						this.angulartics2.eventTrack.next({
							action: 'Update fiscal year',
							properties: {
								category: 'Organization Settings'
							}
						});
					}
				})
			);
	}
}
