import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Location } from '../../main/organization/locations/models/location.model';
import { LocationsService } from '../../main/organization/locations/services/locations.service';
import { delayedRetry } from '../operators/delayed-retry';
import { CacheService } from '../services/cache.service';

@Injectable({
	providedIn: 'root'
})
export class LocationsResolver implements Resolve<Location[]> {

	constructor(private locationsService: LocationsService,
				private cacheService: CacheService) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Location[]> {
		return this.locationsService.getAll({
			sort: {
				field: 'name'
			}
		}).pipe(
			delayedRetry(3000),
			map((response) => response.body.content),
			catchError(() => of([])),
			tap(locations => this.cacheService.setLocations(locations))
		);
	}
}
