import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppGlobals } from '../../common/app.globals';

@Injectable()
export class GoalsRedirectGuard implements CanActivate {

	privileges: {
		company?: boolean;
		department?: boolean;
		team?: boolean;
		personal?: boolean;
	};

	constructor(private router: Router) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		this.privileges = {
			company: AppGlobals.hasComponent('COMPANY_GOALS'),
			department: AppGlobals.hasComponent('DEPARTMENT_GOALS'),
			team: AppGlobals.hasComponent('TEAM_GOALS'),
			personal: AppGlobals.hasComponent('GOALS')
		};

		const prefix = '/goals';

		const company = '/company';
		const department = '/department';
		const team = '/team';
		const personal = '/personal';

		if (state.url === prefix + company && !this.privileges.company) {
			this.router.navigate([prefix + department]);
			return false;
		} else if (state.url === prefix + department && !this.privileges.department) {
			this.router.navigate([prefix + team]);
			return false;
		} else if (state.url === prefix + team && !this.privileges.team) {
			this.router.navigate([prefix + personal]);
			return false;
		} else if (state.url === prefix + personal && !this.privileges.personal) {
			this.router.navigate(['/']);
			return false;
		}

		return true;
	}
}
