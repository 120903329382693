import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpInterceptor } from '../../../../core/http/http.interceptor';
import { PagedResourceInterface } from '../../../../core/interfaces/paged-resource.interface';
import { RequestOptions } from '../../../../core/interfaces/request-options.interface';
import { CrudUsersContainingService } from '../../../../core/services/crud-user-containing.service';
import { Department } from '../models/department.model';

@Injectable({
	providedIn: 'root'
})
export class DepartmentsService extends CrudUsersContainingService<Department, RequestOptions<Department>> {

	constructor(http: HttpInterceptor) {
		super(http, '/departments');
	}


	public getAll(options: RequestOptions<Department>, suffix?: string): Observable<HttpResponse<PagedResourceInterface<Department>>> {
		return super.getAll(options, suffix)
			.pipe(
				map((response) => {
					Department.generateDisplayNames(response.body.content);
					Department.generateHtmlDisplayNames(response.body.content);

					return response;
				})
			);
	}

	public getById(options: RequestOptions<Department>): Observable<HttpResponse<Department>> {
		return super.getById(options)
			.pipe(
				map((response) => {
					Department.generateDisplayName(response.body);
					Department.generateHtmlDisplayName(response.body);

					return response;
				})
			);
	}

	public exportCsv(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/download/csv'), options.data, ['auth', 'blob']);
	}
}
