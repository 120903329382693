import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpInterceptor } from '../../../core/http/http.interceptor';
import { RequestOptions } from '../../../core/interfaces/request-options.interface';
import { CrudTempService } from '../../../core/services/crud-temp.service';
import { EmploymentType } from '../../organization/employment-types/models/employment-type.model';

@Injectable()
export class TempEmploymentTypesService extends CrudTempService<EmploymentType, RequestOptions<EmploymentType>> {

	constructor(http: HttpInterceptor) {
		super(http, '/import/employment-types');
	}

	public getAll(options: RequestOptions<EmploymentType>, suffix?: string): Observable<HttpResponse<any>> {
		this.addExpands(options);
		options.size = 99999;

		return this.http.get(this.getConsolidationUri(), ['auth']);
	}
}
