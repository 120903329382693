import { AfterViewInit, Component, ElementRef, isDevMode, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AppMaintenanceService } from '@app/core/services/app-maintenance.service';
import { MaintenanceCheckService } from '@app/core/utils/maintenance-check.service';
import { VersionCheckService } from '@app/core/utils/version-check.service';
import { AlertsService } from '@helloteaminc/front-common';
import { Angulartics2Piwik } from 'angulartics2/piwik';
import * as moment from 'moment';
import { setTheme } from 'ngx-bootstrap/utils';
import { CookieService } from 'ngx-cookie';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AppConfigService } from './core/services/app-config.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

	destroy$: Subject<boolean> = new Subject<boolean>();

	mutationObserver: MutationObserver;

	maintenance$ = this.appMaintenanceService.maintenance$;

	brandName = '';
	brandLogo = 'assets/images/logo.svg';

	constructor(
		private cookieService: CookieService,
		private router: Router,
		private appMaintenanceService: AppMaintenanceService,
		private maintenanceCheckService: MaintenanceCheckService,
		private versionCheckService: VersionCheckService,
		private angulartics2Piwik: Angulartics2Piwik,
		private elRef: ElementRef,
		private renderer: Renderer2,
		private appConfig: AppConfigService,
		private titleService: Title,
	) {
		// Set bootstrap theme to bootstrap v3
		setTheme('bs3');
		this.brandName = this.appConfig.getAppSettings().appName;
		this.titleService.setTitle(`${this.brandName}`);

		if (!isDevMode()) {
			this.angulartics2Piwik.startTracking();
		}
	}

	ngOnInit() {
		this.versionCheckService.initVersionCheck(environment.versionCheckURL, 1000 * 60);
		this.maintenanceCheckService.initMaintenanceCheck(environment.maintenanceCheckURL, 1000 * 60);
		this.router.events.pipe(
			takeUntil(this.destroy$)
		).subscribe((e) => {
			if (e instanceof NavigationEnd) {
				window.scrollTo(0, 0);
			}
		});

		this.initCookieNotify();
		this.brandName = this.appConfig.getAppSettings().appName;
	}

	ngAfterViewInit(): void {
		this.setNgxChartsTooltipsObserver();
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
		this.mutationObserver.disconnect();
	}

	private initCookieNotify(): void {
		if (!this.cookieService.get('atc')) {
			AlertsService.showRichInfoInfoNotice(
				'We use cookies',
				'We use cookies for analytics and to improve our site. You agree to our use of cookies by closing this message box or continuing to use our site. ' +
				'To find out more, see our <a href="https://www.helloteam.com/index.php/cookie-policy/" target="_blank">Cookie Policy</a>', {
				confirm: true,
				buttons: [
					{
						text: 'OK',
						addClass: 'button button-primary',
						click: (notice) => {
							notice.remove();

							if (!this.cookieService.get('atc')) {
								this.cookieService.put('atc', 'true', {
									expires: new Date(moment().add(10, 'years').format('YYYY-MM-DD'))
								});
							}
						}
					}
				]
			});
		}
	}

	private setNgxChartsTooltipsObserver(): void {
		this.mutationObserver = new MutationObserver((mutations) => {
			if (mutations[0].addedNodes.length > 0) {

				setTimeout(() => {
					const elementName = mutations[0].addedNodes[0].nodeName.toLowerCase();
					const tooltipElement = mutations[0].addedNodes[0] as HTMLElement;
					const left = tooltipElement.style.left;

					if (parseFloat(left) < 0 && elementName === 'ngx-tooltip-content') {
						this.renderer.setStyle(tooltipElement, 'left', '50px');
					}
				}, 0);
			}
		});

		const config = { attributes: true, childList: true, characterData: true };
		this.mutationObserver.observe(this.elRef.nativeElement, config);
	}
}
