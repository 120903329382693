export class Queue<T> {
	private items: T[];
	private limit: number;

	constructor(limit: number) {
		this.items = [];
		this.limit = limit;
	}

	getItems(): T[] {
		return this.items;
	}

	push(val: T): T {
		this.items.push(val);

		if (this.items.length > this.limit) {
			return this.pop();
		}
	}

	pop(): T {
		return this.items.shift();
	}
}
