import { environment } from '../../../../environments/environment';

export class Picture {
	name: string;
	extension: string;
	original: string;
	large: string;
	small: string;
	tiny: string;

	built: boolean;

	public static getDefault(): Picture {
		return {
			name: 'DEFAULT',
			extension: '',
			original: '/assets/images/users/default-avatar-bg.svg',
			large: '/assets/images/users/default-avatar-bg.svg',
			small: '/assets/images/users/default-avatar-bg.svg',
			tiny: '/assets/images/users/default-avatar-bg.svg',
			built: true
		};
	}

	public static updatePicture(pic: Picture): void {
		if (!pic) {
			return;
		}

		const url = environment.shareFolderPath;

		if (pic.large.startsWith(url)) {
			return;
		}

		pic.original = url + pic.original;
		pic.large = url + pic.large;
		pic.small = url + pic.small;
		pic.tiny = url + pic.tiny;
		pic.built = true;
	}
}
