import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpInterceptor } from '../http/http.interceptor';
import { RequestOptions } from '../interfaces/request-options.interface';
import { NotificationMessage } from '../models/notification-message.model';
import { BaseService } from './base.service';

@Injectable()
export class NotificationMessagesService extends BaseService<NotificationMessage, RequestOptions<NotificationMessage>> {
	constructor(private httpInterceptor: HttpInterceptor) {
		super(httpInterceptor, '/notification-messages');
	}

	getMessage(options: RequestOptions<NotificationMessage> = {}): Observable<HttpResponse<NotificationMessage>> {
		return this.httpInterceptor.get(this.buildUrl(options), ['auth']);
	}

	markAsSeen(options: RequestOptions<NotificationMessage> = {}): Observable<HttpResponse<NotificationMessage>> {
		return this.httpInterceptor.post(this.buildUrl(options), options.data, ['auth']);
	}

}
