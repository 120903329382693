import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AppMaintenanceService {
	maintenance$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor() {}

	setMaintenance(maintenance: boolean): void {
		this.maintenance$.next(maintenance);
	}
}
