export const environment = {
	production: true,
	apiUrl: '/api/v1',
	apiWsUrl: '/api/v1/ws',
	apiV2Url: '/api/v2',
	versionCheckURL: '/version.json',
	maintenanceCheckURL: '/maintenance',
	shareFolderPath: '/share',
	yearMonthDateFormat: 'MMM YYYY',
	dateFormat: 'DD MMM YYYY',
	dateTimeFormat: 'DD MMM YYYY hh:mm A',
	dateTimeFormatAt: 'DD MMM YYYY[,] hh:mm A',
	apiDateFormat: 'YYYY-MM-DD',
	apiDateTimeFormat: 'YYYY-MM-DDTHH:mm:ssZ',
	apiDateTimeFormatIgnoreTz: 'YYYY-MM-DDTHH:mm:ss',
	apiDateTimeFormatOffsetZero: 'YYYY-MM-DDTHH:mm:ss[Z]',
	apiTimeFormat: 'HH:mm:ss',
	hfCommentLengthLimit: 1000,
	defaultBirthdayYear: '1972',
	rewardsUrl:'/rewards',

	reCaptchaV2: '6LeU6bYpAAAAAHIZvzy_MRD_Cio6O7dPgDbkAyzp'
};
