import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpInterceptor } from '../http/http.interceptor';
import { RequestOptions } from '../interfaces/request-options.interface';
import { BaseService } from './base.service';

@Injectable()
export class OrganizationService extends BaseService<any, RequestOptions<any>> {

	constructor(http: HttpInterceptor) {
		super(http, '/organization');
	}

	resetFakeOrganization(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options, '/reinitialize'), ['auth']);
	}
}
