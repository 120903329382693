import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpInterceptor } from '@app/core/http/http.interceptor';
import { RequestOptions } from '@app/core/interfaces/request-options.interface';
import { BaseService } from '@app/core/services/base.service';
import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ImportStats } from '../import-submit/models/import-stats.model';
import { Integration } from '../models/merge-integration.model';
import { environment } from '../../../../environments/environment';
import { CredentialsResponse } from '../import-paychex/interfaces/import-paychex.interface';

@Injectable()
export class ImportService extends BaseService<any, RequestOptions<any>> {
	constructor(http: HttpInterceptor,
				private router: Router,
				private cookieService: CookieService) {
		super(http, '/import');
	}

	public updateImport(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		options.getParams = options.getParams || {};
		options.getParams['autoUpdate'] = true;

		return this.http.get(this.buildUrl(options), ['auth']);
	}

	updateSalary(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl({}, '/user-payment/' + options.id), options.data, ['auth']);
	}

	public exportCsvCorrect(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/users/export/correct/csv'), options.data, ['auth', 'blob']);
	}

	public exportCsvIncorrect(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/users/export/incorrect/csv'), options.data, ['auth', 'blob']);
	}

	commit(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/commit'), '', ['auth']);
	}

	checkAdpImport(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options) + '/adp-is-finished', ['auth']);
	}

	public getStats(options: RequestOptions<any>): Observable<HttpResponse<ImportStats>> {
		return this.http.post(this.buildUrl(options, '/stats'), '', ['auth'])
			.pipe(
				tap((response) => {
					response.body.notImportable = response.body.correct + response.body.incorrect - response.body.importable;
					response.body.reportsToIncorrect = response.body.notImportable - response.body.incorrect;
				})
			);
	}

	getAdpImportResult(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options) + '/adp-import-result', ['auth']);
	}

	getAllIntegrations$(options: RequestOptions<any>): Observable<HttpResponse<Integration[]>> {
		return this.http.get(this.buildUrl(options) + '/integrations', ['auth']);
	}

	getPrismHrImportResult(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options) + '/prismhr', {}, ['auth']);
	}

	getBambooHrImportResult(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options) + '/bamboohr', {}, ['auth']);
	}

	dropImport(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.delete(this.buildUrl(options), ['auth']).pipe(
			tap(() => this.cookieService.remove('import-progress'))
		);
	}

	downloadSampleCsv(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options, '/samples/csv'), ['auth', 'blob']);
	}

	checkIfTempDataExists(): Observable<boolean> {
		return Observable.create((ob) => {
			this.http.get(this.buildUrl({}), ['auth'])
				.subscribe(() => {
						ob.next(true);
						ob.complete();
					},
					() => {
						ob.next(false);
						ob.complete();
					});
		});
	}

	getAllPaychexCredentails(): Observable<CredentialsResponse> {
		return this.http.get(this.getPaychexImportURL(), ['auth']).pipe(map((response) => response.body));
	}

	addDisplayId(body: { displayId: string }): Observable<any> {
		return this.http.post(this.getPaychexImportURL(), body, ['auth']).pipe(map((response) => response.body));
	}

	fetchLink(body: { displayId: string }): Observable<any> {
		return this.http.post(`${environment.apiV2Url}/paychex/link`, body, ['auth']).pipe(map((response) => response.body));
	}

	checkState(): Observable<HttpResponse<any>> {
		return this.http.get(this.getStateURL(), ['auth']);
	}

	importData(payload): Observable<HttpResponse<any>> {
		return this.http.post(`${this.getBaseURL()}/import`, payload, ['auth']);
	}

	deleteEstablishedLink$(displayId: string): Observable<HttpResponse<any>> {
		return this.http.delete(`${this.getPaychexImportURL()}/${displayId}`, ['auth']);
	}

	private getBaseURL(): string {
		return `${environment.apiV2Url}/paychex`;
	}

	private getStateURL(): string {
		return this.getBaseURL() + '/import/state';
	}

	private getPaychexImportURL(): string {
		return `${environment.apiV2Url}/paychex/credentials`;
	}
}
