import { Injectable } from '@angular/core';
import { HttpInterceptor } from '../../../core/http/http.interceptor';
import { RequestOptions } from '../../../core/interfaces/request-options.interface';
import { CrudService } from '../../../core/services/crud.service';
import { UserPaymentInfo } from '../models/user-payment-info.model';

@Injectable()
export class UserPaymentInfoService extends CrudService<UserPaymentInfo, RequestOptions<UserPaymentInfo>> {

	constructor(http: HttpInterceptor) {
		super(http, '/user-payment');
	}
}
