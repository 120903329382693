import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpInterceptor } from '../../../core/http/http.interceptor';
import { RequestOptions } from '../../../core/interfaces/request-options.interface';
import { CrudTempService } from '../../../core/services/crud-temp.service';
import { Department } from '../../organization/departments/models/department.model';

@Injectable()
export class TempDepartmentsService extends CrudTempService<Department, RequestOptions<Department>> {

	constructor(http: HttpInterceptor) {
		super(http, '/import/departments');
	}


	public getAll(options: RequestOptions<Department>, suffix?: string): Observable<HttpResponse<any>> {
		this.addExpands(options);

		return this.http.get(this.getConsolidationUri(), ['auth']).pipe(
			map((response) => {
				Department.generateDisplayNames(response.body);
				Department.generateHtmlDisplayNames(response.body);

				return response;
			})
		);
	}

}
