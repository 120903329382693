import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpInterceptor } from '../http/http.interceptor';
import { RequestOptions } from '../interfaces/request-options.interface';
import { BaseService } from './base.service';

@Injectable()
export class FileService extends BaseService<any, RequestOptions<any>> {

	constructor(http: HttpInterceptor) {
		super(http, '');
	}

	public uploadImage(url: string, options: RequestOptions<any>): Observable<HttpResponse<any>> {
		let formData: FormData = new FormData();
		let keys = Object.keys(options.files);
		for (let i = 0; i < keys.length; i++) {

			let key = keys[i];
			let fileObjs = options.files[key];
			let dataKey = key;

			if (fileObjs.length > 1) {
				dataKey += '[]';
			}

			for (let j = 0; j < fileObjs.length; j++) {
				formData.append(dataKey, fileObjs[j], fileObjs[j].name);
			}
		}

		formData.append('crop.x', options.data['x']);
		formData.append('crop.y', options.data['y']);
		formData.append('crop.width', options.data['width']);
		formData.append('crop.height', options.data['height']);
		formData.append('rotate', options.data['rotate']);

		return this.http.post(url, formData, ['auth']);
	}
}
