import { MessageTypeEnum } from '../enums/message-type.enum';

export class Message {

	content: string;
	type: MessageTypeEnum;

	constructor(msg: string, type: MessageTypeEnum) {
		this.content = msg;
		this.type = type;
	}

}
