import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpInterceptor } from '../../../../core/http/http.interceptor';
import { RequestOptions } from '../../../../core/interfaces/request-options.interface';
import { CrudUsersContainingService } from '../../../../core/services/crud-user-containing.service';
import { Location } from '../models/location.model';

@Injectable({
	providedIn: 'root'
})
export class LocationsService extends CrudUsersContainingService<Location, RequestOptions<Location>> {

	constructor(http: HttpInterceptor) {
		super(http, '/locations');
	}

	public exportCsv(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/download/csv'), options.data, ['auth', 'blob']);
	}
}
