import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppGlobals } from '../../common/app.globals';

@Injectable()
export class OrganizationRecognitionsRedirectGuard implements CanActivate { // TODO CanLoad for BadgesModule

	privileges: {
		configBadges?: boolean;
		configHighFives?: boolean;
	};

	constructor(private router: Router) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		this.privileges = {
			configBadges: AppGlobals.hasComponent('BADGES:MANAGE'),
			configHighFives: AppGlobals.hasComponent('ORG_SETTINGS_HIGH_FIVES')
		};

		const prefix = '/organization/recognition';

		const badges = '/badges/company-badges';
		const highFives = '/high-fives';

		if (state.url === prefix + badges && !this.privileges.configBadges) {
			this.router.navigate([prefix + highFives]);
			return false;
		} else if (state.url === prefix + highFives && !this.privileges.configHighFives) {
			this.router.navigate(['/']);
			return false;
		}

		return true;


	}
}
