import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpInterceptor } from '../http/http.interceptor';
import { RequestOptions } from '../interfaces/request-options.interface';
import { BaseService } from './base.service';

@Injectable()
export class AccountService extends BaseService<any, RequestOptions<any>> {

	constructor(http: HttpInterceptor) {
		super(http, '/account');
	}

	public acceptTerms(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/terms'), '', ['auth']);
	}

	public getEmailNotificationsSettings(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options, '/preferences/email'), ['auth']);
	}

	public setEmailNotificationsSettings(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/preferences/email'), options.data, ['auth']);
	}

	public getNotificationsSettings(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options, '/notification-settings'), ['auth']);
	}

	public setNotificationsSettings(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/notification-settings'), options.data, ['auth']);
	}

	public getSlackSettings(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options, '/preferences/slack'), ['auth']);
	}

	public getMicrosoftTeamsSettings(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options, '/preferences/microsoft-teams'), ['auth']);
	}

	public setPreferences(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/preferences'), options.data, ['auth']);
	}

	public setPreferencesCountry(countryCode: string): Observable<HttpResponse<any>> {
		return this.http.put(this.buildUrl({}, '/preferences/country?countryCode='+countryCode), {}, ['auth']);
	}

	public getBirthdayNotificationsSettings(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options, '/notification-settings/hierarchy'), ['auth']);
	}

	public setBirthdayNotificationsSettings(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/notification-settings/hierarchy'), options.data, ['auth']);
	}
}
