import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpInterceptor } from '../../../core/http/http.interceptor';
import { RequestOptions } from '../../../core/interfaces/request-options.interface';
import { CrudTempService } from '../../../core/services/crud-temp.service';
import { Department } from '../../organization/departments/models/department.model';
import { User } from '../../users/models/user.model';

@Injectable()
export class TempUsersService extends CrudTempService<User, RequestOptions<User>> {

	constructor(http: HttpInterceptor) {
		super(http, '/import/users');
	}

	public getValidReportsToUri(): string {
		return this.getUri() + '/consolidation/validReportsTo';
	}

	public getHierarchy(options: RequestOptions<User>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options, '/consolidation/hierarchy'), ['auth']);
	}


	public getAll(options: RequestOptions<User>, suffix?: string): Observable<HttpResponse<any>> {
		return super.getAll(options, suffix)
			.pipe(
				map((response) => {
					if (options.expand && options.expand.indexOf('department') > -1) {
						Department.generateDisplayNames(response.body.content.map(x => x.department));
						Department.generateHtmlDisplayNames(response.body.content.map(x => x.department));

						response.body.content.filter(user => user['diffs']?.find(diff => diff.field === 'department')).forEach(user => {
							const departmentDiffs = user['diffs'].find(diff => diff.field === 'department');

							Department.generateDisplayNames([departmentDiffs.from]);
							Department.generateHtmlDisplayNames([departmentDiffs.from]);

							Department.generateDisplayNames([departmentDiffs.to]);
							Department.generateHtmlDisplayNames([departmentDiffs.to]);
						});
					}

					return response;
				})
			);
	}

	getConsolidation(options: RequestOptions<User>, suffix?: string): Observable<HttpResponse<any>> {
		this.addExpands(options);
		return this.http.get(this.getConsolidationUri(), ['auth']).pipe(
			map((response) => {
				if (options.expand && options.expand.indexOf('department') > -1) {
					Department.generateDisplayNames(response.body.map(x => x.department));
					Department.generateHtmlDisplayNames(response.body.map(x => x.department));
				}

				return response;
			})
		);
	}

	public getById(options: RequestOptions<User>): Observable<HttpResponse<User>> {
		return super.getById(options)
			.pipe(
				map((response) => {
					if (options.expand && options.expand.indexOf('department') > -1) {
						if (response.body.department && (typeof response.body.department) !== 'string') {
							Department.generateDisplayName(response.body.department);
							Department.generateHtmlDisplayName(response.body.department);
						}
					}

					return response;
				})
			);
	}

	public mapErrorMessages(errors): void {
		for (let prop in errors) {
			if (errors.hasOwnProperty(prop)) {
				if (errors[prop][0].code.endsWith('.required')) {
					errors[prop][0].message = 'This field is required.';
				}
			}
		}
	}
}
