import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertsService } from '@helloteaminc/front-common';
import isEqual from 'lodash/isEqual';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AppGlobals } from '../../../core/common/app.globals';
import { HttpInterceptor } from '../../../core/http/http.interceptor';
import { RequestOptions } from '../../../core/interfaces/request-options.interface';
import { CrudService } from '../../../core/services/crud.service';
import { HierarchyPermissions } from '../models/hierarchy-permissions.model';
import { Role } from '../models/role.model';
import { UserHierarchicalPermissions } from '../models/user-hierarchical-permissions.model';

@Injectable({
	providedIn: 'root'
})
export class HierarchiesService extends CrudService<Role, RequestOptions<Role>> {

	constructor(http: HttpInterceptor) {
		super(http, '/hierarchies');
	}

	updatePermissions(options: RequestOptions<Role>, hierarchyPermissions: HierarchyPermissions): Observable<HttpResponse<any>> {
		return this.http.patch(this.buildUrl(options), hierarchyPermissions, ['auth']);
	}

	requestHierarchicalPermissions(): Observable<HttpResponse<UserHierarchicalPermissions>> {
		return this.http.get(`${environment.apiUrl}/hierarchies/subordinates`, ['auth'])
			.pipe(
				tap((response) => {
					if (AppGlobals.getLoggedUseHierarchicalPermissions()) {
						const currentPermissions = AppGlobals.getLoggedUseHierarchicalPermissions().permissions || [];
						const newPermissions = response.body.permissions || [];

						if (!isEqual(currentPermissions.sort(), newPermissions.sort())) {
							AlertsService.showInfoNotice('Hierarchical permissions updated', 'Your hierarchical permissions have been updated.');
						}
					}

					AppGlobals.setLoggedUserHierarchicalPermissions(response.body);
				})
			);
	}
}
