import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Position } from '@app/main/organization/positions/models/position.model';
import { PositionsService } from '@app/main/organization/positions/services/positions.service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { delayedRetry } from '../operators/delayed-retry';
import { CacheService } from '../services/cache.service';

@Injectable({
	providedIn: 'root'
})
export class PositionsResolver implements Resolve<Position[]> {

	constructor(private positionsService: PositionsService,
				private cacheService: CacheService) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Position[]> {
		return this.positionsService.getAll({
			sort: {
				field: 'name'
			}
		}).pipe(
			delayedRetry(3000),
			map((response) => response.body.content),
			catchError(() => of([])),
			tap(positions => this.cacheService.setPositions(positions))
		);
	}
}
