import { Injectable } from '@angular/core';
import { Timezone } from '@app/main/account/models/timezone.model';
import { Department } from '@app/main/organization/departments/models/department.model';
import { EmploymentType } from '@app/main/organization/employment-types/models/employment-type.model';
import { Location } from '@app/main/organization/locations/models/location.model';
import { Position } from '@app/main/organization/positions/models/position.model';
import { UserGroup } from '@app/main/organization/user-groups/models/user-group.model';
import { User } from '@app/main/users/models/user.model';

@Injectable({
	providedIn: 'root'
})
export class CacheService {

	private readonly departments: {
		[id: string]: Department
	};
	private readonly positions: {
		[id: string]: Position
	};
	private readonly locations: {
		[id: string]: Location
	};
	private readonly teams: {
		[id: string]: UserGroup
	};
	private readonly employmentTypes: {
		[id: string]: EmploymentType
	};
	private readonly employees: {
		[id: string]: User
	};

	private readonly timezones: {
		[id: string]: Timezone
	};

	constructor() {
		this.departments = {};
		this.positions = {};
		this.locations = {};
		this.teams = {};
		this.employmentTypes = {};
		this.employees = {};
		this.timezones = {};
	}

	getDepartments(): Department[] {
		return Object.values(this.departments);
	}

	setDepartments(departments: Department[]): void {
		departments.forEach(department => this.departments[department.id] = department);
	}

	getPositions(): Position[] {
		return Object.values(this.positions);
	}

	setPositions(positions: Position[]): void {
		positions.forEach(position => this.positions[position.id] = position);
	}

	getLocations(): Location[] {
		return Object.values(this.locations);
	}

	setLocations(locations: Location[]): void {
		locations.forEach(location => this.locations[location.id] = location);
	}

	getTeams(): UserGroup[] {
		return Object.values(this.teams);
	}

	setTeams(teams: UserGroup[]): void {
		teams.forEach(team => this.teams[team.id] = team);
	}

	setTimezones(timezones: Timezone[]): void {
		timezones.forEach(timezone => this.timezones[timezone.zoneId] = timezone);
	}

	getEmploymentTypes(): EmploymentType[] {
		return Object.values(this.employmentTypes);
	}

	setEmploymentTypes(employmentTypes: EmploymentType[]): void {
		employmentTypes.forEach(employmentType => this.employmentTypes[employmentType.id] = employmentType);
	}

	getEmployees(): User[] {
		return Object.values(this.employees);
	}

	setEmployees(employees: User[]): void {
		employees.forEach(employee => this.employees[employee.id] = employee);
	}

	getDepartment(id: string): Department {
		return this.departments[id];
	}

	getPosition(id: string): Position {
		return this.positions[id];
	}

	getLocation(id: string): Location {
		return this.locations[id];
	}

	getTeam(id: string): UserGroup {
		return this.teams[id];
	}

	getEmploymentType(id: string): EmploymentType {
		return this.employmentTypes[id];
	}

	getEmployee(id: string): User {
		return this.employees[id];
	}

	getTimezones(): Timezone[] {
		return Object.values(this.timezones);
	}
}
