import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { AlertsService } from '@helloteaminc/front-common';
import { BaseQueryShareCriteria } from '../../main/queries/common/models/base-query-share-criteria.model';
import { ErrorResponse, Errors } from '../common/error-response.model';
import { DatetimeUtil } from '../utils/datetime.util';

@Injectable({
	providedIn: 'root'
})
export class FormValidationService {

	static passwordValidators = [
		FormValidationService.required,
		FormValidationService.passwordValidator,
		FormValidationService.charsRepeatValidation
	];

	static emailValidator(control: AbstractControl): { [key: string]: any } | null {
		if (control.value && control.value.match(/^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@[A-Z0-9.-]+\.[A-Z]{2,}$/i)) {
			return null;
		}

		return { 'invalidEmailAddress': true };
	}

	static number(control: AbstractControl): { [key: string]: any } | null {
		if (!isNaN(control.value)) {
			return null;
		}

		return { 'invalidNumber': true };
	}

	static passwordValidator(control: AbstractControl): { [key: string]: any } | null {
		if (control.value && (!control.value == null || !control.value.length)) {
			return null;
		} else if (control.value && control.value.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,30}$/g)) {
			return null;
		} else {
			return { 'invalidPassword': true };
		}
	}

	static charsRepeatValidation(control: AbstractControl): { [key: string]: any } | null {
		if (control.value && (!control.value == null || !control.value.length)) {
			return null;
		} else if (control.value && !control.value.match(/(.)\1\1/g)) {
			return null;
		} else {
			return { 'passwordCharsRepeat': true };
		}
	}

	static matchPasswords(formGroup: FormGroup) {
		const password = formGroup.value.password;
		const confirmPassword = formGroup.value.rePassword;

		if (password !== confirmPassword) {
			const errors = formGroup.get('rePassword').errors ? formGroup.get('rePassword').errors : {};
			errors['passwordNotMatch'] = true;
			formGroup.get('rePassword').setErrors(errors, { emitEvent: true });
		} else {
			if (formGroup.get('rePassword').dirty) {
				formGroup.get('rePassword').markAsTouched();
			}

			return null;
		}
	}

	static requireCheckboxesToBeCheckedValidator(minRequired = 1, maxRequired = 4): ValidatorFn | null {
		return (group: FormGroup): { [key: string]: any, minRequired: number } | { [key: string]: any, maxRequired: number } | null => {
			let checked = 0;

			Object.keys(group.controls).forEach(key => {
				const control = group.controls[key];

				if (control.value === true) {
					checked++;
				}
			});

			if (checked < minRequired) {
				return { requireCheckboxesToBeChecked: true, minRequired: minRequired };
			}

			if (checked > maxRequired) {
				return { requireMaxCheckboxesToBeChecked: true, maxRequired: maxRequired };
			}

			return null;
		};
	}

	static processScoreValidator(): ValidatorFn | null {
		return (group: FormGroup): { [key: string]: any, scoreRange: string } => {
			let processesScores = 0;
			const processesControls = (group.get('processes') as FormArray).controls;

			processesControls.forEach((processGroup) => {
				processesScores += processGroup.get('weight').enabled ? processGroup.get('weight')?.value || 0 : 0;
			});

			const areScoresIncorrect = processesScores !== 100;

			processesControls.forEach((processGroup) => {
				let errors = processGroup.get('weight').errors;

				// these checks are needed to ensure that other control errors are not deleted
				if (areScoresIncorrect && processGroup.get('weight').enabled) {
					if (errors) {
						errors['incorrectProcessScore'] = true;
					} else {
						errors = { incorrectProcessScore: true };
					}
				} else {
					if (errors) {
						delete errors['incorrectProcessScore'];

						// if there aren't any more errors in the error object it is set as null
						// because an empty object is still reduced to a truthy value
						errors = Object.keys(errors).length ? errors : null;
					}
				}

				processGroup.get('weight').setErrors(errors);
			});

			return null;
		};
	}

	static dateHiredValidator(formGroup: FormGroup) {
		const dateHiredControl = formGroup.get('dateHired');
		const dateHired = formGroup.value.dateHired;
		const terminationDate = formGroup.value.terminationInfo.terminationDate;

		if (DatetimeUtil.isAfter(dateHired, terminationDate)) {
			const errors = dateHiredControl.errors || {};
			errors['dateHiredAfterTerminationDate'] = true;
			dateHiredControl.setErrors(errors, { emitEvent: true });
		} else {
			if (dateHiredControl.dirty) {
				dateHiredControl.markAsTouched();
			}

			return null;
		}
	}

	static terminationDateValidator(formGroup: FormGroup) {
		const terminationDateControl = formGroup.get('terminationInfo.terminationDate');
		const dateHired = formGroup.value.dateHired;
		const terminationDate = formGroup.value.terminationInfo.terminationDate;

		if (DatetimeUtil.isBefore(terminationDate, dateHired)) {
			const errors = terminationDateControl.errors || {};
			errors['terminationDateBeforeDateHired'] = true;
			terminationDateControl.setErrors(errors, { emitEvent: true });
		} else {
			if (terminationDateControl.dirty) {
				terminationDateControl.markAsTouched();
			}

			return null;
		}
	}

	static required(control: AbstractControl) {
		const value = control.value;

		if (!value || !value.trim().length) {
			return { 'required': true };
		}

		return null;
	}

	static validate(formGroup: FormGroup, markFormGroupAsTouched?: boolean) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			if (control instanceof FormControl) {
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {
				if (markFormGroupAsTouched) {
					control.markAsTouched({ onlySelf: true });
				}
				this.validate(control);
			} else if (control instanceof FormArray) {
				control.markAsTouched();
			}
		});
	}

	static urlValidator(control: AbstractControl): { [key: string]: any } | null {
		if (control && (!control.value || !control.value.length)) {
			return null;
		} else if (control.value && control.value.match(/^(http(s)?:\/\/)?(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,16}(:[0-9]{1,5})?(\/.*)?$/)) {
			return null;
		} else {
			return { 'invalidURL': true };
		}
	}

	static validatePhoneNumber(control: AbstractControl): { [key: string]: any } {
		const value = control.value;
		if (!value) {
			return null;
		}

		const isPhoneValid = value && value.match(/^((?:(\(\+?[0-9 ]+\))|(\+\(?[0-9 ]+\))|(?:\+?[0-9]+ \([0-9 ]+\))|(\+?[0-9 ]*))(?:([0-9 ])|([ ]*\([0-9 ]\)))[0-9- ]*)(?:[0-9])$/i);

		if (isPhoneValid) {
			return null;
		}

		return { 'phoneIsInvalid': true };
	}

	static domainValidation(control: AbstractControl): { [key: string]: any } | null {
		const isDomainValid = /^(?!.*--)[a-z0-9][a-z0-9-]{0,30}[a-z0-9]$/.test(control.value);

		if (!isDomainValid) {
			return { 'domainIsInvalid': true };
		}
		return null;
	}

	static futureRangeValidation(control: AbstractControl): { [key: string]: any } | null {
		if (!control || !control.value) {
			return null;
		}

		const endDate = control.value[1];
		const isRangePast = DatetimeUtil.isBeforeNow(endDate);

		if (!isRangePast) {
			return null;
		}

		return { 'periodIsPast': true };
	}

	static googleMapDomainValidator(control: AbstractControl): { [key: string]: any } | null {
		if (control && (!control.value || !control.value.length)) {
			return null;
		} else if (control.value && control.value.match(/(^https?:\/\/(www\.|maps\.)?google(\.[a-z]+){1,2}\/maps.+)|(^https:\/\/goo.gl\/maps\/.+)/i)) {
			return null;
		} else {
			return { 'invalidGoogleMapDomain': true };
		}
	}

	static showApiErrors(formGroup: FormGroup, response: ErrorResponse<Errors>): void {
		const fields = Object.keys(response.errors);
		fields.forEach((field) => {
			const control = formGroup.get(field);
			const error = ErrorResponse.getFieldError(response, field);

			if (!control || field === 'id') {
				AlertsService.showErrorNotice('Error', error.message);
				return;
			}

			control.setErrors({
				[error.code]: error.message
			});

			control.markAsTouched();
		});
	}

	static checkBoxGroupValidation(formGroup: FormGroup) {
		let checked = 0;

		Object.keys(formGroup.controls).forEach(key => {
			const control = formGroup.controls[key];

			if (control.value === true) {
				checked++;
			}
		});
		// at least one checkbox to be checked
		if (checked < 1) {
			return { 'required': true };
		}

		return null;
	}

	static employeeSelectionValidation(formGroup: FormGroup): { [key: string]: any } | null {
		const value: BaseQueryShareCriteria = formGroup.value;

		if (
			value.all ||
			(value.departments.length > 0 ||
				value.locations.length > 0 ||
				value.userGroups.length > 0 ||
				value.userIds.length > 0)) {
			return null;
		}

		return { 'invalidUserCriteria': true };
	}

	static maxLengthSkippingHtmlTags(maxLength): ValidatorFn | null {
		return (control: AbstractControl): { [key: string]: any, maxLength: number } | null => {
			const valueWithoutHtmlTags = control.value ? control.value.replace(/<.*?>/g, '').replace(/&.*?;/g, '-') : '';
			if (valueWithoutHtmlTags.trim().length > maxLength) {
				return { 'moreThanMaxLength': true, maxLength: maxLength };
			}
			return null;
		};
	}
}
