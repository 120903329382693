import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { EmploymentType } from '../../main/organization/employment-types/models/employment-type.model';
import { EmploymentTypesService } from '../../main/organization/employment-types/services/employment-types.service';
import { delayedRetry } from '../operators/delayed-retry';
import { CacheService } from '../services/cache.service';

@Injectable({
	providedIn: 'root'
})
export class EmploymentTypesResolver implements Resolve<EmploymentType[]> {

	constructor(private employmentTypesService: EmploymentTypesService,
				private cacheService: CacheService) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<EmploymentType[]> {
		return this.employmentTypesService.getAll({
			sort: {
				field: 'title'
			}
		}).pipe(
			delayedRetry(3000),
			map((response) => response.body.content),
			catchError(() => of([])),
			tap(employmentTypes => this.cacheService.setEmploymentTypes(employmentTypes))
		);
	}
}
