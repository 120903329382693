import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

export abstract class BaseGuard implements CanLoad, CanActivate {

	constructor(protected provider: () => Observable<any>) {
	}

	canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
		return this.check();
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		return this.check();
	}

	protected abstract handle(val: any): boolean;

	protected check(): boolean | Observable<boolean> {
		let value = this.provider();

		if (value instanceof Observable) {
			return this.observableHandle(value);
		}

		return this.handle(value);
	}

	protected observableHandle(obs: Observable<any>): Observable<boolean> {
		return Observable.create((ob) => {
			obs.subscribe((val) => {
				ob.next(this.handle(val));
				ob.complete();
			});
		});
	}
}
