import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ProfileAcl } from '../../../main/professional-profile/models/profile-acl.model';
import { AppGlobals } from '../../common/app.globals';

@Injectable()
export class ProfileACLGuard implements CanActivate {

	constructor(private router: Router) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		const userId = route.params['id'];
		const profileAcl = route.data as ProfileAcl;

		const hasAccess = this.hasAccess(profileAcl, userId);

		if (!hasAccess) {
			this.router.navigate(['/']);
		}

		return hasAccess;
	}

	private hasAccess(profileAcl: ProfileAcl, userId: string): boolean {
		const userComponents = AppGlobals.getLoggedUser().components;

		if (userId === AppGlobals.getLoggedUser().id) {
			// check for personal permissions when the user is viewing their own profile
			for (let i = 0; i < profileAcl.personalAcl.length; i++) {
				if (userComponents.includes(profileAcl.personalAcl[i])) {
					return true;
				}
			}
		} else {
			// check for manage permissions when the user is viewing someone else's profile
			for (let i = 0; i < profileAcl.manageAcl.length; i++) {
				if (userComponents.includes(profileAcl.manageAcl[i])) {
					return true;
				}
			}

			// check for hierarchical permissions when the user is viewing someone else's profile
			for (let i = 0, j = profileAcl.hierarchicalAcl.length; i < j; i++) {
				if (AppGlobals.hasAnyHierarchyComponent(profileAcl.hierarchicalAcl[i], userId)) {
					return true;
				}
			}

			// check for regular permission when the user is viewing their subordinate's profile
			if (AppGlobals.hasAnyComponent(profileAcl?.subordinatesAcl?.acl || [])) {
				const loggedUserSubordinates = AppGlobals.getLoggedUseHierarchicalPermissions();
				let hasAccess = false;

				if (profileAcl.subordinatesAcl.directSubordinatesOnly) {
					hasAccess = loggedUserSubordinates.directReportees.includes(userId);
				} else {
					hasAccess =
						loggedUserSubordinates.directReportees.includes(userId) ||
						loggedUserSubordinates.secondTierReportees.includes(userId) ||
						loggedUserSubordinates.infinityUp.includes(userId);
				}

				return hasAccess;
			}
		}

		return false;
	}
}
