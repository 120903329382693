import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { AppMaintenanceService } from '@app/core/services/app-maintenance.service';

interface MaintenanceVersion {
	maintenance: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class MaintenanceCheckService {
	constructor(
		private http: HttpClient,
		private appMaintenanceService: AppMaintenanceService
	) {}

	public initMaintenanceCheck(url: string, frequency = 1000 * 60 * 30): void {
		this.checkMaintenance(url);
		setInterval(() => {
			this.checkMaintenance(url);
		}, frequency);
	}

	private checkMaintenance(url: string): void {
		this.http
			.get<MaintenanceVersion>(`${url}?t=${new Date().getTime()}`)
			.pipe(
				take(1),
				catchError(() => {
					console.error('catchError MaintenanceCheck');

					return EMPTY;
				})
			)
			.subscribe(
				({ maintenance }) => {
					this.appMaintenanceService.setMaintenance(maintenance);
				},
				(err) => {
					console.error(err, 'Could not get maintenance');
				}
			);
	}
}
