import { Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';

import { jsPDF } from 'jspdf';
import { ExportOptions } from '../../main/shared/models/export-options';

declare global {
	interface Navigator {
		msSaveBlob: (blobOrBase64: Blob | string, filename: string) => void;
	}
}
@Injectable()
export class ExportUtil {

	constructor(private angulartics2: Angulartics2) {
	}

	public exportToPng(options: ExportOptions): void {
		let $source;
		let id = options.id;
		if (options.sourceObj) {
			$source = options.sourceObj;
		} else {
			if (!options.id.startsWith('#')) {
				options.id = '#' + options.id;
			}

			$source = $(options.id);
		}
		// Set explocity style for svg elements of ngx-charts

		// remove black fill of grouped bar charts
		let svgGridPanelOdds = document.getElementById(id).getElementsByClassName('odd');
		for (let i = 0; i < svgGridPanelOdds.length; ++i) {
			let curSvg = svgGridPanelOdds[i];
			curSvg.setAttribute('style', 'fill: #F2F2F2');
		}

		let svgGridPanelEvens = document.getElementById(id).getElementsByClassName('even');
		for (let i = 0; i < svgGridPanelEvens.length; ++i) {
			let curSvg = svgGridPanelEvens[i];
			curSvg.setAttribute('style', 'fill: #FFFFFF');
		}

		// vertical lines
		let verticalLines = document.getElementById(id).getElementsByClassName('gridline-path');
		for (let i = 0; i < verticalLines.length; ++i) {
			let curSvg = verticalLines[i];
			curSvg.setAttribute('stroke', '#ddd');
		}

		let gs = document.getElementById(id).getElementsByTagName('g');
		for (let i = 0; i < gs.length; ++i) {
			let svg = gs[i];
			svg.setAttribute('font-family', 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif');
			svg.setAttribute('font-weight', 'bold');
		}

		let $hideOnExport = $source.find('.hide-on-export');
		let $showOnExport = $source.find('.show-on-export');
		$hideOnExport.hide();
		$showOnExport.show();
		html2canvas($source.get(0), {
			useCORS: true,
			backgroundColor: options.background,
			scale: this.scaleCanvas($source.get(0))
		}).then((canvas: any) => {
			if (options.onRendered) {
				options.onRendered();
			}

			if (this.isIE() || this.isEdge()) { // fix for IE export
				window.navigator.msSaveBlob(canvas.msToBlob(), options.title + '.png');
			} else {
				canvas2image.saveAsPNG(canvas, options.title);
			}

			$hideOnExport.show();
			$showOnExport.hide();

			this.angulartics2.eventTrack.next({
				action: 'Export ' + options.title,
				properties: {
					category: 'Export'
				}
			});

			if (options.onSuccess) {
				options.onSuccess();
			}
		});
	}

	public exportToJPEG(options: ExportOptions): void {
		if (!options.id.startsWith('#')) {
			options.id = '#' + options.id;
		}

		let $source = $(options.id);
		let $hideOnExport = $source.find('.hide-on-export');
		let $showOnExport = $source.find('.show-on-export');
		$hideOnExport.hide();
		$showOnExport.show();

		html2canvas($source.get(0), {
			useCORS: true,
			backgroundColor: options.background,
		}).then((canvas: any) => {
			if (options.onRendered) {
				options.onRendered();
			}

			if (this.isIE() || this.isEdge()) { // fix for IE export
				window.navigator.msSaveBlob(canvas.msToBlob(), options.title + '.jpeg');
			} else {
				canvas2image.saveAsJPEG(canvas, options.title);
			}

			$hideOnExport.show();
			$showOnExport.hide();

			this.angulartics2.eventTrack.next({
				action: 'Export ' + options.title,
				properties: {
					category: 'Export'
				}
			});

			if (options.onSuccess) {
				options.onSuccess();
			}
		});
	}

	public exportAsPdf(options: ExportOptions): void {
		let $source;

		if (options.sourceObj) {
			$source = options.sourceObj;
		} else {
			if (!options.id.startsWith('#')) {
				options.id = '#' + options.id;
			}

			$source = $(options.id);
		}

		let $hideOnExport = $source.find('.hide-on-export');
		let $showOnExport = $source.find('.show-on-export');
		$hideOnExport.hide();
		$showOnExport.show();

		html2canvas($source.get(0), {
			useCORS: true,
			backgroundColor: options.background,
			scale: this.scaleCanvas($source.get(0))
		}).then((canvas) => {
			if (options.onRendered) {
				options.onRendered();
			}

			let doc: any;
			let docWidth = Math.floor(canvas.width);
			let docHeight = Math.floor(canvas.height);
			if (docWidth > docHeight) {
				doc = new jsPDF('l', 'mm', <any>[docWidth, docHeight]);
			} else {
				doc = new jsPDF('p', 'mm', <any>[docHeight, docWidth]);
			}
			doc.addImage(canvas.toDataURL(), 'png', 0, 0);
			doc.save(options.title + '.pdf');

			$hideOnExport.show();
			$showOnExport.hide();

			if (options.onSuccess) {
				options.onSuccess();
			}
		});
	}

	private isIE(): boolean {
		let userAgent = window.navigator.userAgent;
		let msie = userAgent.indexOf('MSIE ');       // Detect IE
		let trident = userAgent.indexOf('Trident/'); // Detect IE 11

		return (msie > 0 || trident > 0);
	}

	private isEdge(): boolean {
		return window.navigator.userAgent.indexOf('Edge') > -1;
	}

	// Reduce canvas scale to keep canvas size within browser limitations.
	// Browser limits: Maximum height/width: 32,767 pixels; Maximum area: 268,435,456 pixels
	private scaleCanvas(canvas: Element): number {
		let scale = 1;

		const maxSide = 32_767;
		const maxArea = 268_435_456;

		const width = canvas.clientWidth;
		const height = canvas.clientHeight;
		const area = width * height;

		if (width >= maxSide || height >= maxSide) {
			scale = maxSide / (width >= height ? width : height);
		}

		if (area >= maxArea) {
			scale = maxArea / area;
		}

		return scale;
	}
}
