import { BaseModel } from '../../../../core/interfaces/base.model';
import { User } from '../../../users/models/user.model';

export class Department implements BaseModel {
	id: string;
	key: string;
	name: string;
	parentId: string;
	managers: User[];
	hierarchy: string[];

	imported: boolean;
	uses: number;

	displayName: string;
	displayNameHtml: string;

	public static generateDisplayName(department: Department): void {
		if (!department || typeof department === 'string') {
			return;
		}

		if (department.displayName) {
			return;
		}

		if (department.hierarchy && department.hierarchy.length) {
			department.displayName = '';

			department.hierarchy.forEach(x => {
				department.displayName += x + ' : ';
			});

			department.displayName += department.name;
		} else {
			department.displayName = department.name;
		}
	}

	public static generateDisplayNames(departments: Department[]): void {
		if (!departments) {
			return;
		}

		departments.forEach(d => {
			Department.generateDisplayName(d);
		});
	}

	public static generateHtmlDisplayName(department: Department): void {
		if (!department || typeof department === 'string') {
			return;
		}

		if (department.displayNameHtml) {
			return;
		}

		department.displayNameHtml = '<span>';

		if (department.hierarchy && department.hierarchy.length) {
			department.displayNameHtml += '<span class="text-muted">';

			department.hierarchy.forEach(x => {
				department.displayNameHtml += x + ' : ';
			});

			department.displayNameHtml += '</span>';

			department.displayNameHtml += department.name;
		} else {
			department.displayNameHtml += department.name;
		}

		department.displayNameHtml += '</span>';
	}

	public static generateHtmlDisplayNames(departments: Department[]): void {
		if (!departments) {
			return;
		}

		departments.forEach(d => {
			Department.generateHtmlDisplayName(d);
		});
	}

	public static generateHierarchyDisplayName(department: Department): string {
		if (!department.hierarchy) {
			throw new Error('Department has not hierarchy');
		}

		let hierarchy = '';

		if (department.hierarchy && department.hierarchy.length) {
			department.hierarchy.forEach(x => {
				hierarchy += x;
				if (department.hierarchy.indexOf(x) < department.hierarchy.length - 1) {
					hierarchy += ' : ';
				}
			});
		}

		return hierarchy;
	}
}
