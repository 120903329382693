import { Injectable } from '@angular/core';
import { CanLoad, Route, Router } from '@angular/router';
import { OrganizationsService } from '@app/core/services/organizations.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class InitialSetupDoneGuard implements CanLoad {

	constructor(private organizationsService: OrganizationsService,
				private router: Router) {
	}

	canLoad(route: Route): Observable<boolean> {
		return this.organizationsService.checkInitialSetup$().pipe(
			map(response => {
				const hasInitialSetup = response.initialSetup;

				if (hasInitialSetup) {
					return true;
				} else {
					this.router.navigate(['startup']);
					return false;
				}
			})
		);
	}
}
