import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Department } from '../../main/organization/departments/models/department.model';
import { DepartmentsService } from '../../main/organization/departments/services/departments.service';
import { delayedRetry } from '../operators/delayed-retry';
import { CacheService } from '../services/cache.service';

@Injectable({
	providedIn: 'root'
})
export class DepartmentsResolver implements Resolve<Department[]> {

	constructor(private departmentsService: DepartmentsService,
				private cacheService: CacheService) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Department[]> {
		return this.departmentsService.getAll({
			sort: {
				field: 'name'
			}
		}).pipe(
			delayedRetry(3000),
			map((response) => response.body.content),
			catchError(() => of([])),
			tap(departments => this.cacheService.setDepartments(departments))
		);
	}
}
