import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AppGlobals } from '../common/app.globals';
import { HttpInterceptor } from '../http/http.interceptor';
import { RequestOptions } from '../interfaces/request-options.interface';
import { CountryCode } from '../models/country-code.model';
import { BaseService } from './base.service';

@Injectable()
export class GatewayService extends BaseService<any, RequestOptions<any>> {

	private perksweetUrl = `${environment.apiUrl}/perksweet`;

	constructor(private httpInterceptor: HttpInterceptor) {
		super(httpInterceptor, '/gateway');
	}

	irewardsSaveApiToken(options: RequestOptions<any> = {}): Observable<HttpResponse<any>> {
		return this.httpInterceptor.post(this.buildUrl(options, '/irewards/token'), options.data, ['auth']);
	}

	irewardsDeleteApiToken(options: RequestOptions<any> = {}): Observable<HttpResponse<any>> {
		return this.httpInterceptor.delete(this.buildUrl(options, '/irewards/token'), ['auth']);
	}

	irewardsCheckforApiToken(options: RequestOptions<any> = {}): Observable<HttpResponse<any>> {
		return this.httpInterceptor.get(this.buildUrl(options, '/irewards/token'), ['auth']);
	}

	getAvailableCountries(options: RequestOptions<any> = {}): Observable<CountryCode[]> {
		return this.httpInterceptor.get(this.buildUrl(options, '/countries'), []).pipe(
			map((response) => [...response.body].sort((a, b) => a.displayCountry.localeCompare(b.displayCountry)))
		);
	}

	saveCredentials(options: RequestOptions<any> = {}): Observable<HttpResponse<any>> {
		return this.httpInterceptor.post(this.buildUrl(options, '/irewards/config'), options.data, ['auth']);
	}

	getUserSettings(options: RequestOptions<any> = {}): Observable<HttpResponse<any>> {
		return this.httpInterceptor.get(this.buildUrl({}, '/irewards/config/' + options.id), ['auth']);
	}

	updatePerksweetConfiguration(userId: string): Observable<HttpResponse<void>> {
		return this.httpInterceptor.post(`${this.perksweetUrl}/update`, { userId: userId }, ['auth']);
	}

	getUserPoints(userId: string): Observable<HttpResponse<any>> {
		return this.httpInterceptor.get(this.isIrewards() ? this.buildUrl({}, '/irewards/points/' + userId) : `${this.perksweetUrl}/points/${userId}`, ['auth']);
	}

	rewardsAuthRequest(options: RequestOptions<any> = {}): Observable<HttpResponse<any>> {
		return this.httpInterceptor.get(this.isIrewards() ? this.buildUrl(options, '/irewards') : `${this.perksweetUrl}/login`, ['auth']);
	}

	private isIrewards(): boolean {
		return AppGlobals.hasComponent('ORG_IREWARDS_INTEGRATION');
	}
}
