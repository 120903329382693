import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertsService } from '@helloteaminc/front-common';
import { CookieService } from 'ngx-cookie';
import { ImportService } from '../../../main/import/services/import.service';
import { BaseGuard } from './base.guard';

@Injectable()
export class TempOrganizationExistsGuard extends BaseGuard {

	constructor(private importService: ImportService,
				private cookieService: CookieService,
				private router: Router) {
		super(() => importService.checkIfTempDataExists());
	}

	protected handle(val: any): boolean {
		if (!val) {
			AlertsService.showErrorAlert('No data', 'Please import data first!');

			this.cookieService.remove('import-progress');
			this.router.navigate(['/import']);
		}

		return !!val;
	}
}
