import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AppConfigService } from '../../core/services/app-config.service';
import { Role } from '../../main/permissions/models/role.model';
import { HierarchiesService } from '../../main/permissions/services/hierarchies.service';
import { AppGlobals } from '../common/app.globals';
import { Organization } from '../models/organization.model';
import { OrganizationsService } from '../services/organizations.service';

@Injectable({
	providedIn: 'root'
})
export class PendoConfigResolver implements Resolve<any> {

	constructor(
		private organizationsService: OrganizationsService,
		private config: AppConfigService,
		private hierarchiesService: HierarchiesService
	) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
		const activateFor = ['prod-hpc'];
		if (!activateFor.includes(this.config.getConfig().name)) {
			return of({});
		}

		return this.hierarchiesService.requestHierarchicalPermissions().pipe(
			switchMap(() => this.organizationsService.getData({})),
			tap((response) => {
				const organization: Organization = response.body;
				const user = AppGlobals.getLoggedUser();

				let userId = user.id;
				if (this.config.getConfig().name !== 'production') {
					userId = `${this.config.getConfig().name}_${user.id}`;
				}

				pendo.initialize({
					visitor: {
						id: userId,
						email: user.email,
						full_name: user?.name,
						position: user.position?.name,
						location: user.location?.name,
						roles: (this.filterHierarchyRoles() || []).map(r => r.name).join(', ')
					},

					account: {
						id: organization.domain,
						name: organization.name,
						industry: organization.industry,
						employees_number: organization.empNumber,
						enviornment: this.config.getConfig().name
					}
				});
			})
		);
	}

	private filterHierarchyRoles(): Role[] {
		const hierarchicalPermissions = AppGlobals.getLoggedUseHierarchicalPermissions();
		let filteredRoles = JSON.parse(JSON.stringify(AppGlobals.getLoggedUser().roles));

		if (!hierarchicalPermissions?.directReportees?.length) {
			filteredRoles = filteredRoles.filter((role: Role) => role.key !== 'ROLE_DIRECT_MANAGER');
		}

		if (!hierarchicalPermissions?.secondTierReportees?.length) {
			filteredRoles = filteredRoles.filter((role: Role) => role.key !== 'ROLE_MANAGERS_MANAGER');
		}

		if (!hierarchicalPermissions?.infinityUp?.length) {
			filteredRoles = filteredRoles.filter((role: Role) => role.key !== 'ROLE_INFINITE_UP_THE_HIERARCHY');
		}

		return filteredRoles;
	}
}
