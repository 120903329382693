import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppGlobals } from '../../common/app.globals';

@Injectable()
export class OrganizationSettingsRedirectGuard implements CanActivate {

	privileges: {
		changeRoot?: boolean;
		changeFiscalYear?: boolean;
		modifyLoginOptions?: boolean;
		modifyFeatures?: boolean;
		configDigestEmail?: boolean;
		configIntegrations?: boolean;
		configOneOnOnes?: boolean;
		modifySurveys?: boolean;
		configLMS?: boolean;
	};

	constructor(private router: Router) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		this.privileges = {
			changeRoot: AppGlobals.hasComponent('ORG_SETTINGS_GENERAL'),
			changeFiscalYear: AppGlobals.hasComponent('ORG_SETTINGS_FISCAL_YEAR'),
			modifyLoginOptions: AppGlobals.hasComponent('ORG_SETTINGS_LOGIN_OPTIONS'),
			modifyFeatures: AppGlobals.hasComponent('ORG_SETTINGS_FEATURES'),
			configDigestEmail: AppGlobals.hasComponent('ORG_SETTINGS_DIGEST_EMAILS'),
			configIntegrations: AppGlobals.hasComponent('ORG_SLACK_INTEGRATION:SETTINGS'),
			configOneOnOnes: AppGlobals.hasComponent('ORG_SETTINGS_ONE_ON_ONE_TEMPLATES'),
			modifySurveys: AppGlobals.hasComponent('SURVEYS:MANAGE_ALL'),
			configLMS: AppGlobals.hasComponent('ORG_LMS_INTEGRATION')
		};

		const prefix = '/organization/settings';

		const headOfOrganization = '/general-settings';
		const fiscalYear = '/fiscal-year';
		const loginOptions = '/login-options';
		const features = '/features';
		const digestEmails = '/digest-emails';
		const integrations = '/integrations';
		const oneOnOnes = '/one-on-ones';
		const surveys = '/surveys';
		const lms = 'lms';

		if (state.url === prefix + headOfOrganization && !this.privileges.changeRoot) {
			this.router.navigate([prefix + fiscalYear]);
			return false;
		} else if (state.url === prefix + fiscalYear && !this.privileges.changeFiscalYear) {
			this.router.navigate([prefix + loginOptions]);
			return false;
		} else if (state.url === prefix + loginOptions && !this.privileges.modifyLoginOptions) {
			this.router.navigate([prefix + features]);
			return false;
		} else if (state.url === prefix + features && !this.privileges.modifyFeatures) {
			this.router.navigate([prefix + digestEmails]);
			return false;
		} else if (state.url === prefix + digestEmails && !this.privileges.configDigestEmail) {
			this.router.navigate([prefix + integrations]);
			return false;
		} else if (state.url === prefix + integrations && !this.privileges.configIntegrations) {
			this.router.navigate([prefix + oneOnOnes]);
			return false;
		} else if (state.url === prefix + oneOnOnes && !this.privileges.configOneOnOnes) {
			this.router.navigate([prefix + surveys]);
			return false;
		}  else if (state.url === prefix + surveys && !this.privileges.modifySurveys) {
			this.router.navigate(['/']);
			return false;
		} else if (state.url === prefix + lms && !this.privileges.configLMS) {
			this.router.navigate(['/']);
			return false;
		}

		return true;
	}
}
